import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, TemplateRef} from "@angular/core";
import {ValioInputComponent} from "../../../input/valio-input.component";
import {NgbDate,NgbDateStruct, NgbDateParserFormatter, NgbDatepickerConfig, NgbDatepickerI18n} from "@ng-bootstrap/ng-bootstrap";
import {ValioNgbDatepickerI18n} from "./valio-datepicker-i18n";
import {ValioNgbDateParserFormatter} from "./valio-datepicker-parser-formatter";
import {FormUtils} from "../../../misc/util/form-utils";


@Component({
    selector: 'valio-datepicker-input',
    templateUrl: './valio-datepicker-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NgbDatepickerI18n, useClass: ValioNgbDatepickerI18n },
        { provide: NgbDateParserFormatter, useClass: ValioNgbDateParserFormatter }
    ],
    standalone: false
})
export class ValioDatepickerInputComponent extends ValioInputComponent implements OnDestroy {

  @Input()
  selectedDate: NgbDate;
  @Input()
  customDayTemplate: TemplateRef<any>;
  @Output()
  navigationArrowClickEvent = new EventEmitter<NgbDate>();
  @Output()
  onChangeEvent = new EventEmitter<NgbDate>();

  constructor(config: NgbDatepickerConfig,
              protected cdr: ChangeDetectorRef,
              protected elRef: ElementRef,
              protected ngbDatepickerI18n: NgbDatepickerI18n,
              protected ngbDateParserFormatter: NgbDateParserFormatter) {
    super(elRef);
    config.navigation = "arrows";
    // days that don't belong to current month are not visible and the space should be collapsed
    config.showWeekNumbers = true;
    config.outsideDays = "collapsed";
  }

  hasValue(): boolean {
    if (this.parentForm) {
      const input: any = this.parentForm.get(this.nameForm);
      return input && input.value && (this.isNotEmptyString(input) || this.isNgbDateStruct(input));
    }
    return false;
  }

  isNotEmptyString(object: any): boolean {
    const value: any = object.value;
    return value && typeof value === 'string' && value !== '';
  }

  isNgbDateStruct(object: any): object is NgbDateStruct {
    const value: any = object.value;
    return 'year' in value && 'month' in value && 'day' in value;
  }

  getFormattedDate(): string {
    if (this.selectedDate) {
      return this.ngbDateParserFormatter.format(this.selectedDate);
    }
    return null;
  }

  isNotValid(formControlName: string): boolean {
    return FormUtils.isNotValidField(
      this.parentForm,
      formControlName,
      this.submitClicked
    );
  }

  ngOnDestroy(): void {
    // Providers should be destroyed manually
    if (this.ngbDatepickerI18n instanceof ValioNgbDatepickerI18n) {
      this.ngbDatepickerI18n.ngOnDestroy();
    }
    if (this.ngbDateParserFormatter instanceof ValioNgbDateParserFormatter) {
      this.ngbDateParserFormatter.ngOnDestroy()
    }
  }

  navigate(date: NgbDate) {
    this.navigationArrowClickEvent.emit(date);
  }

  fixWeekHeader(): void {
    /* Fix for week header, by default it's empty div without any value */
    const div = this.elRef.nativeElement.querySelector('.ngb-dp-showweek');
    if (div !== undefined && div !== null && this.ngbDatepickerI18n instanceof ValioNgbDatepickerI18n) {
      div.textContent = this.ngbDatepickerI18n.weekHeader;
    }
  }

  onChange(date: NgbDate) {
    this.onChangeEvent.emit(date);
  }
}
