import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import {ValioSwitchPaymentComponent} from "./valio-switch-payment.component";
import {ValioCartPartnerGroupedEntryWrapper} from "../../../../../services/cart/valio-cart.objects";

export const SWITCH_PAYMENT = 'SWITCH_PAYMENT';
export const valioSwitchPaymentLayout: LayoutConfig = {
  launch: {
    SWITCH_PAYMENT: {
      inlineRoot: true,
      component: ValioSwitchPaymentComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export interface ValioSwitchPaymentDialogData {
  partnerEntry: ValioCartPartnerGroupedEntryWrapper,
  toCard: boolean
}

