import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { CheckoutAdapter, CheckoutConnector, CheckoutQueryService } from "@spartacus/checkout/base/core";
import { Occ, Price, PointOfService } from '@spartacus/core';
import { Order, ORDER_NORMALIZER } from "@spartacus/order/root";
import { ValioOccCartNormalizer } from "../cart/valio-cart-normalizer";
import { ValioCartPaymentModeGroupedEntryWrapper } from "../cart/valio-cart.objects";
import { ValioCheckoutDetailsService } from "./valio-checkout-details.service";
import { ValioCheckoutConnector } from './valio-checkout.connector';
import { ValioCheckoutEffects } from "./valio-checkout.effect";
import { ValioCheckoutService } from "./valio-checkout.service";
import { ValioOccCheckoutAdapter } from "./valio-occ-checkout.adapter";

@NgModule({
  providers: [
    ValioOccCheckoutAdapter,
    ValioCheckoutService,
    {
      provide: CheckoutQueryService,
      useClass: ValioCheckoutDetailsService
    },
    {
      provide: CheckoutConnector,
      useClass: ValioCheckoutConnector
    },
    {
      provide: CheckoutAdapter,
      useClass: ValioOccCheckoutAdapter
    },
    {
      provide: ORDER_NORMALIZER,
      useClass: ValioOccCartNormalizer,
      multi: true
    }
  ],
  imports: [
    EffectsModule.forFeature([ValioCheckoutEffects]),
  ]
})
export class ValioCheckoutStoreModule {
}

export interface ValioOrder extends Order {
  paymentModeGroupedEntries: { [deliveryDate: string]: ValioCartPaymentModeGroupedEntryWrapper };
  requestedDate: string;
  status: string;
  totalPriceWithoutTax: Price;
  freightCharge?: Price;
  variableWeightPaymentCost?: Price;
  subtotal?: Price;
  selectedPickupPoint?: PointOfService;
}

export interface ValioOccOrder extends Occ.Order {
  paymentModeGroupedEntries: { [deliveryDate: string]: ValioCartPaymentModeGroupedEntryWrapper };
}
