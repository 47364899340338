import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {ValioReviewSubmitComponent} from "../valio-review-submit.component";
import {DOCUMENT, Location} from '@angular/common';
import {ValioCartService} from "../../../../../services/cart/valio-cart.service";
import {RoutingService} from "@spartacus/core";
import {ValioCheckoutService} from "../../../../../services/checkout/valio-checkout.service";
import {ValioDatePipe} from "../../../../../services/pipes/valio-date.pipe";
import {ValioCartPaymentModeGroupedEntryWrapper} from "../../../../../services/cart/valio-cart.objects";
import {openCloseSpinner} from "../../../../../services/util/valio-modals-utils";
import {DateUtils} from "../../../../misc/util/date-utils";

@Component({
    selector: 'valio-cx-review-submit-invoice',
    templateUrl: './valio-review-submit-invoice.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioReviewSubmitInvoiceComponent extends ValioReviewSubmitComponent {
  buttonTextKey = 'checkout.header.approve';

  constructor(
    protected cartService: ValioCartService,
    protected routingService: RoutingService,
    protected checkoutService: ValioCheckoutService,
    protected cxDate: ValioDatePipe,
    protected location: Location,
    @Inject(DOCUMENT) protected document: Document) {
    super(cartService, routingService, checkoutService, cxDate);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  placeOrderAndNext(date: string, group: ValioCartPaymentModeGroupedEntryWrapper): void {
    if (ValioReviewSubmitInvoiceComponent.isClosingTimeOk(group)) {
      this.checkoutService.placeInvoiceOrderForDate(date);
    } else {
      openCloseSpinner(this.document, false);
      this.buttonTextKey = 'checkout.header.closingTimePassed';
    }
  }

  public static isClosingTimeOk(group: ValioCartPaymentModeGroupedEntryWrapper): boolean {
    var ok = true;
    Array.from(group.entries)
      .forEach(partner => partner.entries
        .forEach(e => {
            return ok = ok && (e.statusDisplay != 'open' || new Date(e.salesendDate).getTime() > DateUtils.getNow().getTime());
          }
        )
      );
    return ok;
  }

  goBack(): void {
    this.location.back(); // <-- go back to previous location
  }
}
