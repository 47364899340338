import {Pipe, PipeTransform} from '@angular/core';
import {Price} from "@spartacus/core";

@Pipe({
    name: 'cents',
    standalone: false
})
export class ValioCentPipe implements PipeTransform {
  transform(price: Price): string {
    return price != null ? ((price.value - (price.value | 0))
      .toFixed(3).replace('0.', '')) : '';
  }
}
