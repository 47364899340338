import {Component} from '@angular/core';

@Component({
    selector: 'valio-cx-no-account',
    templateUrl: './valio-no-account.component.html',
    standalone: false
})
export class ValioNoAccountComponent {

}
