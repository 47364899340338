import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ValioCartPaymentModeGroupedEntryWrapper} from "../../../../../services/cart/valio-cart.objects";
import {Observable} from "rxjs";
import {ValioOrder} from "../../../../../services/checkout/valio-checkout.module";
import {ValioCheckoutService} from "../../../../../services/checkout/valio-checkout.service";

@Component({
    selector: 'valio-order-header',
    templateUrl: './valio-order-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioOrderHeaderComponent implements OnInit {
  @Input() date: Date;
  @Input() status: string;
  order$: Observable<ValioOrder>;
  constructor(
    protected checkoutService: ValioCheckoutService
  ) {
  }

  ngOnInit(): void {
    this.order$ = this.checkoutService.getOrderDetails() as Observable<ValioOrder>;
  }

  getInvoiceEntry(paymentModeGroupedEntries: { [p: string]: ValioCartPaymentModeGroupedEntryWrapper }) {
    if (paymentModeGroupedEntries["INVOICE"]) {
      return paymentModeGroupedEntries["INVOICE"];
    }
    return null;
  }
}
