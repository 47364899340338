import {AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {openCloseSpinner} from "./services/util/valio-modals-utils";
import {ValioGoogleAnalyticsService} from "./services/analytics/valio-google-analytics.service";
import {ValioHamburgerMenuService} from './features/layout/hamburger-menu/valio-hamburger-menu.service';
import {Observable, Subscription} from 'rxjs';
import {qualtricsSnippet} from './services/util/valio-qualtrics-utils';
import {ValioEnvironmentService} from "./services/environment/valio-environment.service";
import {Meta} from "@angular/platform-browser";
import {LoggerService, OCC_USER_ID_ANONYMOUS} from "@spartacus/core";
import {ValioUser} from "./models/misc.model";
import {filter, map} from "rxjs/operators";
import {UserAccountFacade} from '@spartacus/user/account/root';
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {ValioWebextendService} from "./services/analytics/valio-webextend.service";
import { AnalyticsConfig } from './services/environment/valio-environment.objects';

declare function reloadOTBanner(): any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'valio-frontend';
  isExpanded$: Observable<boolean>;
  subscriptions: Subscription = new Subscription();
  qualtricsCode: String = undefined;

  constructor(private hamburgerMenuService: ValioHamburgerMenuService,
              protected router: Router,
              protected valioGoogleAnalyticsService: ValioGoogleAnalyticsService,
              protected valioWebextendService: ValioWebextendService,
              protected valioEnvironmentService: ValioEnvironmentService,
              protected userAccountFacade: UserAccountFacade,
              protected renderer: Renderer2,
              protected loggerService: LoggerService,
              @Inject(DOCUMENT) protected document: Document,
              @Inject(PLATFORM_ID) private platformId: Object,
              private metaService: Meta
  ) {

  }

   ngOnInit(): void {
    this.isExpanded$ = this.hamburgerMenuService.isExpanded;
    this.subscriptions.add(this.userAccountFacade.get()
      .pipe(map(user1 => user1 as ValioUser))
      .subscribe(user1 => {
        const uid = user1?.uid;
        if (uid != undefined && uid !== OCC_USER_ID_ANONYMOUS) {
          this.addOrUpdateTag('userId', user1.uid);
          this.addOrUpdateTag('email', user1.email);
          this.addOrUpdateTag('CustomerCompany', user1.activeB2bUnit?.name);
          this.addOrUpdateTag('PhoneNumber', user1.activeB2bUnit?.shippingAddress?.phone);
          this.addOrUpdateTag('CustomerID', user1.activeB2bUnit?.uid);
        } else {
          this.metaService.removeTag("name='userId'");
          this.metaService.removeTag("name='email'");
          this.metaService.removeTag("name='CustomerCompany'");
          this.metaService.removeTag("name='PhoneNumber'");
          this.metaService.removeTag("name='CustomerID'");
        }
      }));
    this.createCookieScript()
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.router.navigated) {
          openCloseSpinner(this.document, false);
        }
      }

      if (event instanceof NavigationEnd) {
        this.valioGoogleAnalyticsService.pageRefreshEvent(event);
        // To avoid the ReferenceError: reloadOTBanner is not defined, in SSR mode, we need to check if the code is running in the browser before calling the function
        if (isPlatformBrowser(this.platformId)) {
          reloadOTBanner();
        }


      }
    });
  }

  createCookieScript(): void {
    const cookieScript = this.document.createElement('script');
    cookieScript.type = 'text/javascript';
    cookieScript.text = 'function reloadOTBanner() {var otConsentSdk = document.getElementById("onetrust-consent-sdk");if (otConsentSdk) {otConsentSdk.remove();}if (window.OneTrust != null) {OneTrust.Init();setTimeout(function() {OneTrust.LoadBanner();var toggleDisplay = document.getElementsByClassName("ot-sdk-show-settings");for (var i = 0; i < toggleDisplay.length; i++) {toggleDisplay[i].onclick = function(event) {event.stopImmediatePropagation();window.OneTrust.ToggleInfoDisplay();};}}, 1000);}}';
    this.document.head.appendChild(cookieScript);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.valioEnvironmentService.analyticsConfig()
      .pipe(
        filter((analyticsConfig:AnalyticsConfig) => analyticsConfig?.qualtricsCode != null),
        map((analyticsConfig:AnalyticsConfig) => analyticsConfig.qualtricsCode),
        filter((code:string) => code != this.qualtricsCode)
      ).subscribe(
        (code:string)=> {
          this.loggerService.debug("Adding qualtrics script");
          qualtricsSnippet(code, this.renderer, this.document);
          this.qualtricsCode = code;
        }
      ));
    this.valioWebextendService.initWebextend();

  }

  private addOrUpdateTag(metaSelector: string, newContent: string) {
    const qualtricsId = "qualtrics_" + metaSelector;
    if (!this.metaService.getTag("name=" + metaSelector)) {
      this.metaService.updateTag({name: metaSelector, id: qualtricsId, content: newContent}, "name=" + metaSelector);
    } else {
      this.metaService.addTag({name: metaSelector, id: qualtricsId, content: newContent});
    }
  }

}

