import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';

import {OccConfig, OccEndpointsService} from "@spartacus/core";
import {Observable} from "rxjs";
import {RouteCalendar} from "../../models/valio-calendar.model";
import {ValioDatePipe} from "../pipes/valio-date.pipe";

@Injectable({
  providedIn: 'root',
})
export class ValioOccRoutecalendarAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected cxDate: ValioDatePipe,
    protected config: OccConfig,
  ) {
  }

  getRouteCalendar(userId: string, cartId: string): Observable<RouteCalendar> {
    const url = this.occEndpoints.buildUrl('routeCalendar', {urlParams: {userId, cartId}});
    return this.http.get<RouteCalendar>(url);
  }
}
