import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  RouteCalendar,
  RouteCalendarActivity,
  RouteDate,
  RouteMonth,
  RouteWeek
} from "../../../models/valio-calendar.model";
import {Observable} from "rxjs";


@Component({
    selector: 'valio-calendar-date-component',
    templateUrl: './valio-calendar-date.component.html',
    standalone: false
})

export class ValioCalendarDateComponent implements OnInit, OnChanges {
  @Input() routeCalendar: RouteCalendar;
  @Input() selectedDate: Date;
  @Input() cartLoaded$: Observable<boolean>;
  @Input() showActivities = true;
  @Output()
  update = new EventEmitter<String>();
  @Output()
  calendarLoaded = new EventEmitter<Date>();

  calendarMonths: RouteMonth[];
  firstAvailableDay: string;
  allDateArray: RouteDate[] = [];

  constructor(protected cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.initCalendar(this.selectedDate);
  }

  initCalendar(selectedDate: Date) {
    if (this.routeCalendar.routeDates && this.routeCalendar.routeDates.length > 0) {
      this.calendarMonths = []
      this.routeCalendar.routeDates
        .forEach(m => this.calendarMonths.push({
            ...m, weeks: m.weeks
              .map(w => ({
                  ...w,
                  days: w.days
                    .map(date => {
                      if (date.deliveryDate && this.firstAvailableDay == null) {
                        this.firstAvailableDay = date.date;
                      }
                      const dayDate = this.convertDate(date.date);
                      const dayDateStr :string = this.removeTimeZone(date.date)
                      this.allDateArray[dayDateStr] = this.removeTimeZone(dayDateStr);
                       return {
                        ...date,
                        dayDate: dayDate,
                        selected: (selectedDate == null ? date.selected : selectedDate.toDateString() == dayDate.toDateString())
                      } as RouteDate;
                    })
                }) as RouteWeek
              )
          }
        ));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedDate && changes.selectedDate.previousValue && changes.selectedDate.currentValue.getTime() > 0) {
      this.selectedDate = changes.selectedDate.currentValue;
      this.initCalendar(this.selectedDate);
      this.cdr.detectChanges();
    }
  }

  getOrderedCartItems(routeDate: RouteDate) {
    if (routeDate != null) {

      return (routeDate.ediLines + routeDate.baseorderLines + routeDate.telesalesLines + routeDate.webLines);
    } else {
      return 0;
    }
  }

  getRouteDate(date: string): RouteDate {

    const routeDate: RouteDate = this.allDateArray[this.removeTimeZone(date)];
    if (routeDate) {
      return routeDate;
    }
    return null;
  }

  selectDay($event: MouseEvent) {
    [].forEach.call(document.getElementsByClassName('js-calendarDay'),
      (element => element.classList.remove("active")));
    const target = ($event.target as HTMLElement).nodeName == 'SPAN' ? ($event.target as HTMLElement).parentElement : ($event.target as HTMLElement);
    target.classList.add("active");
    this.update.emit(target.getAttribute('data-day'));
  }

  isDateSelected(routeDate: RouteDate): boolean {
    return routeDate.selected;
  }

  isActivitySelected(activity: RouteCalendarActivity): boolean {
    if (this.getRouteDate(activity.date) && this.getRouteDate(activity.date).selected != null) {
      return this.getRouteDate(activity.date).selected;
    }
    return false;
  }

  isSameMonth(month: RouteMonth, day: Date): boolean {
    return month.month === day.getMonth();
  }

  isLastWeekOfMonth(week: RouteWeek, month: RouteMonth) {
    return week == month.weeks[month.weeks.length - 1];
  }

  showAllActivityEntries() {
    document.getElementById('activity-entry-wrapper').classList.remove('mobile-view');
    document.getElementById('activity-log-button').classList.add('d-none');
  }

  convertDate(date: string) {
    return new Date(this.removeTimeZone(date)); // get rid of timezone hours, only consider date
  }
  removeTimeZone(date: string) {
    return date.replace(/T.*/,'T00:00:00')
  }
}

