import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ValioUserService} from "../../../services/user/valio-user.service";
import {Observable, Subscription} from "rxjs";
import {ValioDeliveryNote, ValioDeliveryNoteList} from "../../../models/misc.model";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {RoutingService} from "@spartacus/core";
import {ActivatedRoute} from "@angular/router";
import {ValioDatePipe} from "../../../services/pipes/valio-date.pipe";
import {ValioDateRangeSearchComponent} from "./valio-date-range-search.component";
import {DateUtils} from "../../misc/util/date-utils";
import {openCloseSpinner} from "../../../services/util/valio-modals-utils";
import {UserAccountFacade} from '@spartacus/user/account/root';
import {LaunchDialogService} from "@spartacus/storefront";
import {ValioDeliveryNoteReclamationDialogData} from "./reclamation/valio-deliverynote-reclamation-layout.config";
import {DOCUMENT} from "@angular/common";
import {DELIVERY_NOTE_RECLAMATION} from "./reclamation/valio-deliverynote-reclamation-layout.config";
//import Date from "$GLOBAL$";

@Component({
    selector: 'valio-deliverynote-details',
    templateUrl: './valio-deliverynote.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioDeliveryNoteComponent extends ValioDateRangeSearchComponent implements OnInit, OnDestroy {
  deliveryNoteList: Observable<ValioDeliveryNoteList>;

 // @ViewChild('datePickerInput', {static: false})
 // datePickerInput: ValioDatepickerInputComponent;

  fullDeliveryNoteList$: Observable<ValioDeliveryNoteList>;
  subscriptions: Subscription = new Subscription();

  constructor(protected userService: ValioUserService,
              protected userAccountFacade: UserAccountFacade,
              protected launchDialogService: LaunchDialogService,
              protected routerService: RoutingService,
              protected cxDate: ValioDatePipe,
              protected route: ActivatedRoute,
              @Inject(DOCUMENT) private document: Document
  ) {
    super(userAccountFacade, routerService, cxDate, route);
    const startDayPicker = DateUtils.getNow();
    startDayPicker.setTime(startDayPicker.getTime() - 200 * 24 * 60 * 60 * 1000);
    const endDayPicker = DateUtils.getNow();
    endDayPicker.setTime(endDayPicker.getTime() + 30 * 24 * 60 * 60 * 1000);
    this.fullDeliveryNoteList$ = this.userService.getDeliveryNoteList(startDayPicker, endDayPicker);
  }

  ngOnInit() {
    openCloseSpinner(this.document,true);
    super.ngOnInit();
    this.setDeliveryNoteList();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  searchDate(event: NgbDate, from: boolean) {
    openCloseSpinner(this.document, true);
    super.searchDate(event, from);
    if (event) {
      // this.routerService.go('my-account/deliverynotes/' + (this.cxDate.transform(this.fromDate, 'yyyy-MM-dd')), {ignore: true});
      this.setDeliveryNoteList();
    }
  }

  resetView() {
    super.resetView();
    this.setDeliveryNoteList();
  }

  openReclamation(deliveryNoteList: ValioDeliveryNoteList, deliveryNote: ValioDeliveryNote) {
    this.launchDialogService.openDialogAndSubscribe(DELIVERY_NOTE_RECLAMATION, null, {
      deliveryNote: deliveryNote,
      deliveryNoteList: deliveryNoteList
    } as ValioDeliveryNoteReclamationDialogData);
    this.subscriptions.add(this.launchDialogService.dialogClose.subscribe((res) => {
      // on dialog close
      if (res) {
        console.debug("Reclamation modal closed", res);
        this.setDeliveryNoteList();
      }
    }));
  }

  setDeliveryNoteList() {
    openCloseSpinner(this.document,true);
    // Get one week
    this.deliveryNoteList = this.userService.getDeliveryNoteList(this.fromDate, this.toDate);
  }

  toDateArray(deliveryNoteList: ValioDeliveryNoteList): Date[] {
    return deliveryNoteList.weekList
      .map(weekList => weekList.dateList)
      .reduce((x, y) => x.concat(y), [])
      .map(dateList => dateList.date)
      .map(str => new Date(str));
  }
}
