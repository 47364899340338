import {ChangeDetectorRef, Component, ElementRef, Inject, Renderer2} from '@angular/core';
import {AuthService, ProductSearchService, RoutingService, WindowRef} from '@spartacus/core';
import {ValioNavigationUIComponent} from '../valio-navigation-ui.component';
import {Router} from '@angular/router';
import {ValioUserService} from '../../../../services/user/valio-user.service';
import {HamburgerMenuService, LayoutConfig} from "@spartacus/storefront";
import {ValioCategoriesService} from "../../../../services/categories/valio-categories.service";
import {ValioCategoryDataService} from "../../product/product-facet-navigation/valio-category-data.service";
import {ValioVendorService} from "../../../../services/vendor/valio-vendor.service";
import {ValioPartnerSiteService} from "../../../../services/site/valio-partner-site.service";
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {ValioGoogleAnalyticsService} from "../../../../services/analytics/valio-google-analytics.service";
import {ValioHamburgerMenuService} from '../../../layout/hamburger-menu/valio-hamburger-menu.service';
import { UserAccountFacade } from '@spartacus/user/account/root';
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'valio-mobile-navigation-component',
    templateUrl: './valio-mobile-navigation.component.html',
    standalone: false
})
export class ValioMobileNavigationComponent extends ValioNavigationUIComponent {

  constructor(protected  winRef: WindowRef,
              protected valioHamburgerMenuService: ValioHamburgerMenuService,
              protected router2: Router,
              protected renderer2: Renderer2,
              protected elemRef2: ElementRef,
              protected categoriesService: ValioCategoriesService,
              protected auth: AuthService,
              protected userService: ValioUserService,
              protected userAccountFacade: UserAccountFacade,
              protected cdr: ChangeDetectorRef,
              protected config: LayoutConfig,
              protected routerService: RoutingService,
              protected productSearchService: ProductSearchService,
              protected valioCategoryDataService: ValioCategoryDataService,
              protected vendorService: ValioVendorService,
              protected valioPartnerSiteService: ValioPartnerSiteService,
              protected cartService: ValioCartService,
              protected googleAnalyticsService: ValioGoogleAnalyticsService,
              protected hamburgerMenuService: HamburgerMenuService,
              @Inject(DOCUMENT) protected document: Document) {
    super(winRef, valioHamburgerMenuService, router2, renderer2, elemRef2, categoriesService, auth, userService, userAccountFacade, cdr, config, routerService, productSearchService, valioCategoryDataService, vendorService, valioPartnerSiteService, cartService, googleAnalyticsService, hamburgerMenuService,document);
  }
}
