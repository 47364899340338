import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OccCheckoutAdapter} from "@spartacus/checkout/base/occ";
import {ConverterService, InterceptorUtil, OCC_USER_ID_ANONYMOUS, OccConfig, OccEndpointsService, USE_CLIENT_TOKEN} from "@spartacus/core";
import {Order, ORDER_NORMALIZER} from "@spartacus/order/root";
import {Observable} from "rxjs";
import {ValioOccEndpoints} from "../../valio-occ-endpoints";
import {ValioCartService} from "../cart/valio-cart.service";
import {ValioDatePipe} from "../pipes/valio-date.pipe";
import {ValioOccOrder} from "./valio-checkout.module";
import {CheckoutDetails} from "../../features/checkout/core/models/checkout.model";

const FULL_PARAMS = 'fields=FULL';
const ORDERS_ENDPOINT = '/orders';
export const CART_FIELDS = 'fields=DEFAULT,name,erpMessages(DEFAULT),entries(DEFAULT),unit(name,uid),paidAmount(FULL),paidEntries(DEFAULT),appliedVouchers,giftShopPurchaseDateRange(DEFAULT),paymentModeGroupedEntries(DEFAULT),dateGroupedEntries(DEFAULT),totalPrice(formattedValue),totalItems,totalPrice(formattedValue),totalPriceWithoutTax(formattedValue),totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryCost(formattedValue),totalTax(formattedValue),net,user(BASIC),deliveryAddress(FULL),paymentAddress(FULL),allowedPaymentModes(FULL),deliverySlot,availableDeliverySlots,totalWeightInKg,unPaidAmount(FULL),cartStatus,freightCharge(FULL),variableWeightPaymentCost(FULL),variableWeightPaymentCostWithTax(FULL),deliveryItemsQuantity,allowedDeliveryModes(DEFAULT),allowedPickupPoints(DEFAULT),deliveryMode(DEFAULT),schoolMilkAllowed';

@Injectable()
export class ValioOccCheckoutAdapter extends OccCheckoutAdapter {
  endpoints: ValioOccEndpoints;

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected cxDate: ValioDatePipe,
    protected config: OccConfig,
    protected cartService: ValioCartService,
  ) {
    super(http, occEndpoints, converter);
    this.endpoints = this.config.backend.occ.endpoints;
  }

  public placeValioOrder(userId: string, cartId: string, date: Date): Observable<string> {
    const url: string = this.getEndpoint(userId, ORDERS_ENDPOINT);
    const params: HttpParams = new HttpParams({
      fromString: 'cartId=' + cartId + '&requestedDate=' + this.cxDate.transform(date, this.endpoints.dateFormat) + '&' + FULL_PARAMS,
    });
    const headers: HttpHeaders = this.getHeaders(userId);
    return this.http.post(url, {}, {
      headers,
      params,
      responseType: 'text'
    });
  }

  loadOrder(userId: string, date: Date, orderCode?: string): Observable<Order> {
    const headers = this.getHeaders(userId);
    return this.http
      .get<ValioOccOrder>(this.getEndpoint(userId, ORDERS_ENDPOINT) + "/" + (this.cxDate.transform(date, this.endpoints.dateFormat)) + (orderCode ? ("?orderCode=" + orderCode) : ''), {headers})
      .pipe(this.converter.pipeable(ORDER_NORMALIZER));
  }

  loadCheckoutDetails(
    userId: string,
    cartId: string
  ): Observable<CheckoutDetails> {
    const url = this.getEndpoint(userId, '/carts/') + cartId;
    const params = new HttpParams({
      fromString: CART_FIELDS,
    });
    return this.http.get<CheckoutDetails>(url, {params});
  }

  private getHeaders(userId: string): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return headers;
  }

  // Copy from 3.4 sources
  private getEndpoint(userId: string, subEndpoint: string): string {
    const orderEndpoint = 'users/' + encodeURIComponent(userId) + subEndpoint;
    return this.occEndpoints.buildUrl(orderEndpoint);
  }
}
