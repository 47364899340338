<div class="container" *ngIf="cart$|async as cart">
  <div class="cart-header">
    <div class="page-header-back">
      <a (click)="goBack()">
        <span class="valio-icon icon-inline icon-chevron icon-chevron-left margin-right-sm"></span>
        <span class="d-none d-md-inline-block">{{ 'common.return' | cxTranslate }}</span>
      </a>
    </div>
    <div class="page-header-title">
      <h1>{{ 'cart.header.review' | cxTranslate }}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div *ngIf="cart.deliveryAddress?.vatId==null"
           class="message ERROR">{{ 'updateProfileForm.vatId.required'|cxTranslate }}
      </div>
      <div *ngIf="cart.deliveryAddress?.department==null"
           class="message ERROR">{{ 'updateProfileForm.department.required'|cxTranslate }}
      </div>
    </div>
  </div>

  <valio-checkout-header
    [date]="requestedDate$|async"
    [totalPrice]="cart.totalPrice"
    [totalPriceWithTax]="cart.totalPriceWithTax"
    [totalItems]="cart.totalItems"
    [totalWeightInKg]="cart.totalWeightInKg"
    [status]="cart.cartStatus"
  ></valio-checkout-header>

  <valio-cx-shipping-address-details
    [cart]="cart"
    [editable]="false"
    [headerDate]="requestedDate$|async"
  ></valio-cx-shipping-address-details>

  <valio-voucher
    [cart]="cart"
  ></valio-voucher>

  <ng-container *ngIf="cart.paymentModeGroupedEntries as groups">
    <ng-container *ngIf="groups['INVOICE']">
      <div class="submit-type-block">
        <h3>{{ 'cart.header.invoicetype'|cxTranslate }}</h3>
      </div>
      <valio-cx-review-submit-rows
        [cart]="cart"
        [group]="groups['INVOICE']"
        [showButton]="false"
        [title]="'cart.header.invoicetype'"
        [buttonTextKey]="'checkout.header.approved'"
        [showItems]="false"
      >
      </valio-cx-review-submit-rows>
    </ng-container>

    <ng-container *ngIf="groups['CARD']">
      <div class="submit-type-block">
        <h3>{{ 'cart.header.credittype'|cxTranslate }}</h3>
      </div>
      <valio-cx-review-submit-rows
        [cart]="cart"
        [group]="groups['CARD']"
        (clickButton)="placeCreditOrder($event,groups['CARD'])"
        [showButton]="cart.deliveryAddress?.vatId!=null && cart.deliveryAddress?.department!=null"
        [showItems]="true"
        [title]="'cart.header.credittype'"
        [buttonTextKey]="buttonTextKey"
        [switchToCardPayment]="false"
      >
      </valio-cx-review-submit-rows>
    </ng-container>
  </ng-container>
</div>
