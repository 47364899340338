import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ValioProduct} from "../../../../models";
import {ValioCartEntry} from "../../../../services/cart/valio-cart.objects";

@Component({
    selector: 'valio-product-icons',
    templateUrl: './valio-product-icons.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioProductIcons {
  @Input() product: ValioProduct;
  @Input() entry: ValioCartEntry;
}
