import {ChangeDetectionStrategy, Component, OnDestroy, OnInit,} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {ValioCart} from "../../../../../services/cart/valio-cart.objects";
import {ValioCartService} from "../../../../../services/cart/valio-cart.service";
import {OCC_USER_ID_ANONYMOUS, RoutingService, UserIdService} from "@spartacus/core";


@Component({
    selector: 'valio-cx-shipping-address',
    templateUrl: './valio-shipping-address.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioShippingAddressComponent implements OnInit, OnDestroy {
  cart$: Observable<ValioCart>;
  requestedDate$: Observable<Date>;
  cartLoaded$: Observable<boolean>;
  checkoutStepUrlPrevious: string;
  loggedUser: boolean;
  subscription: Subscription = new Subscription();

  constructor(
    protected cartService: ValioCartService,
    protected routingService: RoutingService,
    protected userIdService: UserIdService) {
  }

  ngOnInit(): void {
    this.requestedDate$ = this.cartService.getRequestedDate();
    this.cart$ = this.cartService.getActive();
    this.cartLoaded$ = this.cartService.getLoaded();
    this.checkoutStepUrlPrevious = 'cart';
    this.subscription.add(
      this.userIdService.getUserId().subscribe(userId => this.loggedUser = userId !== OCC_USER_ID_ANONYMOUS)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  back(): void {
    this.routingService.go(this.checkoutStepUrlPrevious);
  }

  goBack(): void {
    if (this.loggedUser) {
      this.routingService.go('cart');
    } else {
      this.routingService.go('/');
    }
  }
}
