import {Inject, Injectable, OnDestroy} from "@angular/core";
import {ValioUser} from "../../models/misc.model";
import {Subscription} from "rxjs";
import {ValioCart, ValioCartEntry} from "../cart/valio-cart.objects";
import {OrderEntry} from "@spartacus/cart/base/root";
import {ValioOrder} from "../checkout/valio-checkout.module";
import {ValioProduct, ValioProductSearchPage} from "../../models";
import {ValioCartService} from "../cart/valio-cart.service";
import {DOCUMENT} from "@angular/common";
import {Order} from "@spartacus/order/root";
import {ValioEnvironmentService} from "../environment/valio-environment.service";
import {ValioProductService} from "../product/valio-product.service";
import {ProductService} from "@spartacus/core";
import {UserAccountFacade} from "@spartacus/user/account/root";

declare var ScarabQueue: any;

@Injectable({
    providedIn: 'root'
  }
)
export class ValioWebextendService implements OnDestroy {

  private subscriptions: Subscription = new Subscription();
  private user: ValioUser;
  private cart: ValioCart;
  private debug: boolean = false;
  private goTimeout = undefined;
  private merchantId: String;
  private enabled: boolean = false;
  private currentSearchTerm: String;
  private currentCategories: CategoryItem[] = [];

  constructor(
    protected userAccountFacade: UserAccountFacade,
    protected cartService: ValioCartService,
    protected valioEnvironmentService: ValioEnvironmentService,
    protected productService: ProductService,
    @Inject(DOCUMENT) protected document: Document,
  ) {
  }

  initWebextend() {
    this.subscriptions.add(this.valioEnvironmentService.analyticsConfig()
      .subscribe(value => {
        if (value?.webextendMerchantId != this.merchantId) {
          this.merchantId = value.webextendMerchantId;
          this.enabled = value.webextendEnabled;
          this.createStartTags(document);
        }
      }));

    this.subscriptions.add(this.userAccountFacade.get()
      .subscribe(user1 => {
        if (user1?.uid) {
          this.setCustomerIdEvent(user1);
          this.user = user1;
        }
      }));
    this.subscriptions.add(this.cartService.getActive().subscribe(cart => {
      this.cartEvent(cart);
    }));
  }

  setCustomerIdEvent(user: ValioUser) {
    this.pushToQueue(['setCustomerId', user.cdcUid]);
    this.goEvent();
  }

  createImpressionEventFromSearchPage(searchPage: ValioProductSearchPage) {
    if (searchPage) {
      if (searchPage.breadcrumbs?.length > 0 && !searchPage.freeTextSearch) {
        const categoryCodes = searchPage.breadcrumbs
          .filter(b => b.facetValueCode.length > 0)
          .map(b => b.facetValueCode);
        if (!this.arraysAreEqual(categoryCodes, this.currentCategories.map(c => c.code))) {
          this.currentCategories = [];
          categoryCodes.forEach(c => {
            if (!this.currentCategories.map(cc => cc.code).includes(c)) {
              (this.productService as ValioProductService).getCategoryById(c).subscribe(vc => {
                if (!this.currentCategories.map(cc => cc.code).includes(c)) {
                  this.currentCategories.push({code: c, name: vc.webextendName});
                }
              });
            }
          })
        }
        this.goEvent();
      }
      if (searchPage.freeTextSearch && this.currentSearchTerm != searchPage.freeTextSearch) {
        this.currentSearchTerm = searchPage.freeTextSearch;
        this.goEvent();
      }
    }
  }

  viewEvent(product: ValioProduct) {
    if (this.isValioPartnerProduct(product)) {
      this.pushToQueue(['view', product.code]);
      this.goEvent();
    }
  }

  purchaseEvent(order: Order): void {
    const valioOrder: ValioOrder = <ValioOrder>order;
    const entries: ValioCartEntry[] = valioOrder.entries.filter(cartEntry => this.isValioPartnerProduct(cartEntry.product));
    if (entries && entries.length > 0 && this.cart?.code) {
      this.pushToQueue(['purchase', {
        orderId: this.cart.code,
        items: entries.map(orderEntry => this.createOrderEntry(orderEntry))
      }]);
      this.goEvent();
    }
  }

  cartEvent(cart: ValioCart) {
    this.cart = cart;
    this.goEvent();
  }

  goEvent() {
    clearTimeout(this.goTimeout);
    this.goTimeout = setTimeout(() => {
      if (this.cart.entries && this.cart.entries.length > 0) {
        const entries = this.cart.entries
          .filter(cartEntry => cartEntry.statusDisplay === 'open')
          .filter(cartEntry => this.isValioPartnerProduct(cartEntry.product))
          .map(cartEntry => this.createCartEntry(cartEntry));
        if (entries && entries.length > 0) {
          this.pushToQueue(['cart', entries]);
        }
      }
      if (this.currentCategories) {
        this.pushToQueue(['category', this.currentCategories
          .sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0))
          .map(c => c.name).join(" > ")]);
        this.currentCategories = [];
      }
      if (this.currentSearchTerm) {
        this.pushToQueue(['searchTerm', this.currentSearchTerm]);
        this.currentSearchTerm = undefined;
      }
      this.pushToQueue(['go']);
    }, 2000);
  }

  private pushToQueue(payload: any[]) {
    if (this.debug) {
      console.log("Webextend command:");
      console.log(payload);
    }
    if (this.enabled) {
      ScarabQueue.push(payload);
    }
  }

  private createCartEntry(cartEntry: ValioCartEntry) {
    return {
      item: cartEntry.product.code,
      price: cartEntry.totalPrice?.value,
      quantity: cartEntry.quantity
    }
  }

  private createOrderEntry(orderEntry: OrderEntry) {
    return {
      item: orderEntry.product.code,
      price: orderEntry.totalPrice?.value,
      quantity: orderEntry.quantity
    }
  }

  private createStartTags(document: Document) {
    const startComment = document.createComment("START EMARSYS WEBEXTEND SCRIPT");

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.classList.add('optanon-category-C0004');
    script.text = `
    var ScarabQueue = ScarabQueue || [];
    (function(subdomain, id) {
      if (document.getElementById(id)) return;
      var js = document.createElement('script'); js.id = id;
      js.src = subdomain + '.scarabresearch.com/js/${this.merchantId}/scarab-v2.js';
      var fs = document.getElementsByTagName('script')[0];
      fs.parentNode.insertBefore(js, fs);
    })('https:' == document.location.protocol ? 'https://recommender' : 'http://cdn', 'scarab-js-api');
    `;

    const endComment = document.createComment("END EMARSYS WEBEXTEND SCRIPT");

    document.head.appendChild(startComment);
    document.head.appendChild(script);
    document.head.appendChild(endComment);
  }

  private arraysAreEqual(arr1: String[], arr2: String[]) {
    if (!arr1 && !arr2) {
      return true;
    } else if (arr1 && arr2) {
      return arr1.every(e => arr2.includes(e)) && arr2.every(e => arr1.includes(e));
    } else {
      return false;
    }

  }

  private isValioPartnerProduct(product: ValioProduct) {
    return product.partnerSupplier.code == "K001000";
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export interface CategoryItem {
  code: string;
  name: string;
}

