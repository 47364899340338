import {Component} from '@angular/core';
import {ValioCartItemComponent} from "../cart-item/valio-cart-item.component";


@Component({
    selector: 'valio-cx-minicart-item',
    templateUrl: './valio-minicart-item.component.html',
    standalone: false
})
export class ValioMiniCartItemComponent extends ValioCartItemComponent{

}
