import {Pipe, PipeTransform} from '@angular/core';
import {Price} from "@spartacus/core";

@Pipe({
    name: 'euros',
    standalone: false
})
export class ValioEuroPipe implements PipeTransform {
  transform(price: Price): number {
    return price != null ? (price.value | 0) : 0;
  }
}
