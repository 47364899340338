import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'valio-cx-checkout-orchestrator',
    templateUrl: './valio-checkout-orchestrator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioCheckoutOrchestratorComponent {
  constructor() {}
}
