import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import {ValioCloseAccountModalComponent} from "./valio-close-account-modal.component";

export const CLOSE_ACCOUNT_MODAL: string = 'CLOSE_ACCOUNT_MODAL'

export const valioCloseAccountModalLayoutConfig: LayoutConfig = {
    launch: {
        CLOSE_ACCOUNT_MODAL: {
            inlineRoot: true,
            component: ValioCloseAccountModalComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        }
    },
};
