import {ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {ValioCartPartnerGroupedEntryWrapper} from "../../../../../services/cart/valio-cart.objects";
import {Subject, Subscription} from "rxjs";
import {ValioCartService} from "../../../../../services/cart/valio-cart.service";
import {LaunchDialogService} from "@spartacus/storefront";
import {ValioSwitchPaymentDialogData} from "../switchpayment/valio-switch-payment-layout.config";
import {SWITCH_PAYMENT} from "../switchpayment/valio-switch-payment-layout.config";

@Component({
    selector: 'valio-cx-cart-details-rows',
    templateUrl: './valio-cart-details-rows.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioCartDetailsRowsComponent implements OnDestroy {
  @Input() partnerEntry: ValioCartPartnerGroupedEntryWrapper;
  @Input() cartLoaded: boolean;
  @Input() isReadOnly: boolean;
  @Input() title: string;
  @Input() collapsed: boolean;
  @Input() switchToCardPayment: boolean;
  @Input() allowDelete = true;

  @ViewChild('partnerToggleButton', {static: false})
  partnerToggleButton: ElementRef;
  partnerClickSubject: Subject<void> = new Subject<void>();

  subscriptions: Subscription = new Subscription();

  constructor(
    protected cartService: ValioCartService,
    protected launchDialogService: LaunchDialogService) {
  }

  togglePartner() {
    this.partnerClickSubject.next();
    this.partnerToggleButton.nativeElement.classList.toggle('collapsed');
  }

  updateCart(data: any): void {
    this.cartService.updateEntryData(data.item.entryNumber, data.data);
  }

  openCardPaymentModal() {
    this.launchDialogService.openDialogAndSubscribe(SWITCH_PAYMENT, null,
      {
        partnerEntry: this.partnerEntry,
        toCard: this.switchToCardPayment
      } as ValioSwitchPaymentDialogData);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
