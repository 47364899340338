import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Actions } from "@ngrx/effects";
import { OrderEntry } from "@spartacus/cart/base/root";
import { OCC_USER_ID_ANONYMOUS } from "@spartacus/core";
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { RouteCalendar } from "../../models/valio-calendar.model";
import { ValioAddress } from "../../services/address/address-model";
import { ValioCart } from "../../services/cart/valio-cart.objects";
import { ValioCartService } from "../../services/cart/valio-cart.service";
import { ValioDatePipe } from "../../services/pipes/valio-date.pipe";
import { ValioRouteCalendarService } from "../../services/routecalendar/valio-routecalendar.service";
import { AnonymousPostalCodeValidator } from "../../services/user/anonymousPostalCode.validator";
import { ValioUserService } from "../../services/user/valio-user.service";
import { DateUtils } from "../misc/util/date-utils";
import { ValioCalendarDataService } from "./valio-calendar-data.service";

@Component({
    selector: 'valio-calendar-component',
    templateUrl: './valio-calendar.component.html',
    standalone: false
})

export class ValioCalendarComponent implements OnDestroy {
  private static calendarActive: boolean = true;
  requestedDate$: Observable<Date>;
  cart$: Observable<ValioCart>;
  cartLoaded$: Observable<boolean>;
  entries$: Observable<OrderEntry[]>;
  isSignedIn: boolean;
  routeCalendar$: Observable<RouteCalendar>;


  form: UntypedFormGroup;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected userService: ValioUserService,
    protected userAccountFacade: UserAccountFacade,
    protected cartService: ValioCartService,
    protected routeCalendarService: ValioRouteCalendarService,
    protected cdr: ChangeDetectorRef,
    protected valioCalendarDataService: ValioCalendarDataService,
    protected fb: UntypedFormBuilder,
    protected anonymousPostalCodeValidator: AnonymousPostalCodeValidator,
    protected cxDate: ValioDatePipe,
    protected actions$: Actions
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(this.userService.isSignedIn().subscribe(signed => {
      this.isSignedIn = signed;
      try {
        this.cdr.detectChanges();
      } catch (e) {
      }
    }));

    this.requestedDate$ = this.cartService.getRequestedDate();
    this.cart$ = this.cartService.getActive();
    this.cartLoaded$ = this.cartService.getLoaded();
    this.entries$ = this.cartService.getEntries();
    this.valioCalendarDataService.currentMessage.subscribe(active => this.setCalendarActive(!active));
    this.routeCalendar$ = this.routeCalendarService.getRouteCalendar()
      .pipe(tap(cal => {
        this.cdr.detectChanges();
        this.scrollActivityLog(cal.currentDate);
      }));
    this.userAccountFacade.get().subscribe(user => {
      if (user?.uid === OCC_USER_ID_ANONYMOUS) {
        ValioCalendarComponent.calendarActive = false;
      }
    }
    );
  }

  selectDate(date: string) {
    this.cartService.changeDate(date);
  }

  isCalendarActive() {
    return ValioCalendarComponent.calendarActive && this.isSignedIn;
  }

  isMiniCartActive(cart: ValioCart) {
    return !this.isCalendarActive() && (this.isSignedIn ||
      cart.dateGroupedEntries
        ?.filter(e => e.deliveryDate == cart.requestedDate)
        .filter(e => e.entries.length > 0).length > 0);
  }

  isCheckoutActive(cart: ValioCart, entries: OrderEntry[]) {
    return entries.length >= 0
      && cart.dateGroupedEntries?.filter(e => e.deliveryDate == cart.requestedDate).find(e => e.validForOrdering)
      && (this.form == null || this.form.valid);
  }

  setCalendarActive(calendarActive) {
    ValioCalendarComponent.calendarActive = calendarActive;
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  updateCart(data: any): void {
    this.cartService.updateEntryData(data.item.entryNumber, data.data);
  }

  scrollActivityLog(selectedDate: string) {
    if (this.isCalendarActive()) {
      const activity = document.getElementById('activity_' + selectedDate);
      if (activity) {
        document.getElementById('activity-entry-wrapper').scrollTop = activity.offsetTop + 4;
        //document.getElementById('activity-entry-wrapper').scrollTo({top: activity.offsetTop, behavior: 'smooth'}) //Let's try this later
      }
      const day = document.getElementById('day_' + selectedDate);
      if (day) {
        const week = <HTMLElement>day.closest('.calendar-week-row');
        if (week) {
          document.getElementById('calendar-wrapper').scrollTop = week.offsetTop > 152 ? week.offsetTop - 152 : 0;
        }
      }
    }
  }

  toCheckout(date: Date, cart: ValioCart) {
    this.cartService.checkout(date, cart.paymentModeGroupedEntries['CARD'] != null && cart.paymentModeGroupedEntries['CARD'].hasUnapprovedLines);
  }

  isInvalidPostalCode(): boolean {
    return this.form.get('anonymousPostalCode').invalid;
  }

  changePostalCode() {
    if (this.form.valid) {
      this.cartService.changeShiptoAddress({ postalCode: this.form.get('anonymousPostalCode').value } as ValioAddress);
      this.cdr.detectChanges();
    }
  }

  createShippingForm(cart: ValioCart) {
    if (this.form == null) {
      this.form = this.fb.group(
        {
          anonymousPostalCode: [cart.deliveryAddress.postalCode, {
            validators: Validators.required,
            asyncValidators: this.anonymousPostalCodeValidator.validate.bind(this.anonymousPostalCodeValidator)
          }]
        });
    }
  }

  convertDateToUi(date: string): string {
    return this.cxDate.transform(DateUtils.convertDate(date), 'dd.MM.yyyy');
  }
}
