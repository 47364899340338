import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CustomFormValidators} from '@spartacus/storefront';
import {ValioUserService} from "../../../../services/user/valio-user.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {B2BUnit, ValioUser, Vendor} from "../../../../models/misc.model";
import VatIdValidator from "../../../../shared/utils/validators/VatIdValidator";
import {ValioRegisterExistingComponent} from "../../user/register/valio-register-existing.component";
import {CmsService, RoutingService} from "@spartacus/core";
import {ActivatedRoute} from "@angular/router";
import {ValioVendorService} from "../../../../services/vendor/valio-vendor.service";
import {Partner} from "../../../../services/cart/valio-cart.objects";
import {ValioGoogleAnalyticsService} from "../../../../services/analytics/valio-google-analytics.service";
import { UserAccountFacade } from '@spartacus/user/account/root';



@Component({
    selector: 'valio-cx-partner-apply',
    templateUrl: './valio-partner-apply.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioPartnerApplyComponent implements OnInit{
  partner$: Observable<Vendor>;
  partnerIcon$: Observable<any>;
  customerNumbers$: Observable<B2BUnit[]>;
  submitted: boolean;
  result: string;

  form: UntypedFormGroup;

  constructor(
    protected fb: UntypedFormBuilder,
    protected userAccountFacade : UserAccountFacade,
    protected userService : ValioUserService,
    protected routingService: RoutingService,
    protected activatedRoute: ActivatedRoute,
    protected vendorService: ValioVendorService,
    protected cdr: ChangeDetectorRef,
    protected cmsService: CmsService,
    protected googleAnalyticsService: ValioGoogleAnalyticsService) {


  }

  ngOnInit() {
    this.form = this.fb.group(
      {
        customerNumber: [null, Validators.required],
        vatId: ['', [Validators.required, VatIdValidator.valid]],
        companyName: ['', Validators.required],
        contact: ['', Validators.required],
        billingName: ['', Validators.required],
        billingStreet: ['', Validators.required],
        billingPostalcode: ['', Validators.required],
        billingCity: ['', Validators.required],
        shippingName: ['', Validators.required],
        shippingStreet: ['', Validators.required],
        shippingPostalcode: ['', Validators.required],
        shippingCity: ['', Validators.required],
        shippingPhone: ['', ValioRegisterExistingComponent.phoneNumberValidator],
        shippingEmail: ['', [Validators.required, CustomFormValidators.emailValidator]],
        info: ['']
      },
      {}
    );
    const url = this.activatedRoute.snapshot.url;
    const param = url[url.length - 1].path;
    if (param != 'OK' && param != 'NOT-OK') {
      this.partner$ = this.vendorService.getVendor(param);
      this.partnerIcon$ = this.cmsService.getComponentData<any>("Partner_Logo_" + param);
      this.customerNumbers$ = this.userAccountFacade.get()
        .pipe(map( user=> user as ValioUser))
        .pipe(map(
        user => {
          if (user.activeB2bUnit) {
            this.form.controls['customerNumber'].setValue(user.activeB2bUnit.uid);
            return user.b2bUnits.map(unit => ({...unit, name: unit.uid + ', ' + unit.name}));
          }
        }
        )
      );
    } else {
      this.result = param;
    }
  }

  isInvalidStep(fieldName: string): boolean {
    return this.submitted && this.form.get(fieldName).invalid;
  }

  cancel() {
    this.routingService.go('sopimusasiakkuus');
  }

  send(partner: Partner) {
    this.submitted = true;
    if (this.form.valid) {
      this.userService.applyForPartner(partner, this.form.getRawValue()).subscribe(success => {
          if (success) {
            this.result = 'OK';
            this.googleAnalyticsService.createApplyForContractCustomerEvent(partner);
            this.routingService.go('hae-sopimusasiakkaaksi/OK');
          } else {
            this.result = 'NOT-OK';
            this.routingService.go('hae-sopimusasiakkaaksi/NOT-OK');
          }
          this.cdr.detectChanges();
        }
      );
    }
  }
}
