import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {ValioCart} from "../../../../services/cart/valio-cart.objects";
import {LaunchDialogService} from "@spartacus/storefront";
import {ValioOrderTemplatesService} from "../../../../services/orderTemplates/valio-order-templates.service";
import {ValioUser} from "../../../../models/misc.model";
import {map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {UserAccountFacade} from '@spartacus/user/account/root';
import {
  CREATE_ORDER_TEMPLATE,
  ValioCreateOrderTemplateDialogData
} from "../popup/valio-create-order-template-layout.config";

@Component({
    selector: 'valio-cx-order-template-list',
    templateUrl: './valio-order-template-list.component.html',
    standalone: false
})
export class ValioOrderTemplateListComponent implements OnInit, OnDestroy {
  orderTemplates$: Observable<ValioCart[]>;
  user$: Observable<ValioUser>;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected orderTemplatesService: ValioOrderTemplatesService,
    protected launchDialogService: LaunchDialogService,
    protected userAccountFacade: UserAccountFacade
  ) {
  }

  ngOnInit(): void {
    this.orderTemplates$ = this.orderTemplatesService.getOrderTemplates();
    this.user$ = this.userAccountFacade.get().pipe(map(user => (user as ValioUser)));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  isActive(path: string): Observable<boolean> {
    return this.route.url.pipe(
      map(url => url.toString().includes(path))
    );
  }

  openCreateTemplate() {
    this.launchDialogService.openDialogAndSubscribe(CREATE_ORDER_TEMPLATE, null, {
      titleKey: 'myaccount.orderTemplate.new',
      buttonKey: 'myaccount.orderTemplate.newBtn'
    } as ValioCreateOrderTemplateDialogData);
  }

  switchTemplate(code: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl('/my-account/modify-order-template/' + code);
    });
  }
}
