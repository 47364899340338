import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  ConverterService,
  OccConfig,
  OccEndpointsService,
  PRODUCT_REFERENCES_NORMALIZER,
  ProductReference,
  ProductReferencesAdapter
} from "@spartacus/core";
import {filter, flatMap, map} from "rxjs/operators";
import {ValioCartService} from "../cart/valio-cart.service";
import {ValioOccEndpoints} from "../../valio-occ-endpoints";
import {ValioDatePipe} from "../pipes/valio-date.pipe";

@Injectable()
export class ValioOccProductReferencesAdapter implements ProductReferencesAdapter {
  endpoints: ValioOccEndpoints;

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected cartService: ValioCartService,
    protected cxDate: ValioDatePipe,
    protected config: OccConfig
  ) {
    this.endpoints = this.config.backend.occ.endpoints;
  }

  load(
    productCode: string,
    referenceType?: string,
    pageSize?: number
  ): Observable<ProductReference[]> {
    return this.getEndpoint(productCode, referenceType, pageSize).pipe(flatMap(url => this.http.get(url)
      .pipe(this.converter.pipeable(PRODUCT_REFERENCES_NORMALIZER))));
  }

  protected getEndpoint(code: string,
                        reference?: string,
                        pageSize?: number): Observable<string> {
    return this.cartService.getRequestedDate()
      .pipe(
        filter(date => date != null),
        map(date => this.occEndpoints.buildUrl(
          'productReferences',
          {
            urlParams: {
              productCode: code,
            },
            queryParams: {
              referenceType: reference,
              pageSize: pageSize,
              requestedDate: this.cxDate.transform(date, this.endpoints.dateFormat)
            }
          })
        ));
  }

}
