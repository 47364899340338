import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ValioDeliveryNote} from "../../../models/misc.model";

@Component({
    selector: 'valio-deliverynote-row',
    templateUrl: './valio-deliverynote-row.html',
    standalone: false
})
export class ValioDeliveryNoteRow {
  @Input()
  deliveryNote: ValioDeliveryNote;
  @Input()
  isLast: boolean;
  @Input()
  isFirst: boolean;
  @Input()
  isDayView;

  @Output()
  modalWindowOpenEvent = new EventEmitter<any>();

  convertDate() {
    return new Date(this.deliveryNote.deliveryDate);
  }

  openCreateReclamationWindow() {
    this.modalWindowOpenEvent.emit(this.deliveryNote);
  }

  getWeekdayName(): string {
    return 'cart.header.weekday.' + (1 + new Date(this.deliveryNote.deliveryDate).getDay());
  }

}
