import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ValioUserService} from "../../../../services/user/valio-user.service";
import {LaunchDialogService} from "@spartacus/storefront";
import {openCloseSpinner} from "../../../../services/util/valio-modals-utils";
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'valio-cx-new-contract',
    templateUrl: './valio-new-contract.component.html',
    standalone: false
})
export class ValioNewContractComponent implements OnInit {

  submitted = false;
  emailError: boolean;
  emailSend: boolean;

  form: UntypedFormGroup;

  constructor(protected fb: UntypedFormBuilder,
              protected userService: ValioUserService,
              protected launchDialogService: LaunchDialogService,
              protected cdr: ChangeDetectorRef,
              @Inject(DOCUMENT) protected document: Document) {
  }

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        name: ['', Validators.required],
        customerNumbersGroup: this.fb.array([]),
        message: [''],
      },
      {}
    );

  }

  isInvalidStep2(fieldName: string): boolean {
    return this.submitted && this.form.get(fieldName).invalid;
  }

  submit(): void {
    openCloseSpinner(this.document, true);
    if (this.form.invalid) {
      this.submitted = true;
    } else {
      const sub = this.userService.sendNewContractRequest(this.form.getRawValue()).subscribe(
        ret => {
          openCloseSpinner(this.document, false);
          sub.unsubscribe();
          if (ret) {
            this.emailSend = true;
          } else {
            this.emailError = true;
          }
        }
      );
    }
  }

  cancel() {
    this.launchDialogService.closeDialog(null);
  }
}
