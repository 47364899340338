import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {CmsBannerComponent} from "@spartacus/core";
import {ValioNavigationService} from "../../navigation/valio-navigation.service";
import {Observable} from "rxjs";


@Component({
    selector: 'valio-cx-background-image',
    templateUrl: './valio-background-image.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioBackgroundImageComponent implements OnInit {

  data$: Observable<CmsBannerComponent>

  constructor(
    protected componentData: CmsComponentData<CmsBannerComponent>,
    protected service: ValioNavigationService
  ) {
   }

  ngOnInit(): void {
    this.data$ = this.componentData.data$;

  }

}
