import {Component, OnInit, Renderer2} from '@angular/core';
import {FormUtils} from "../../../misc/util/form-utils";
import {UpdatePasswordComponent} from '@spartacus/user/profile/components';
import {ValioUpdatePasswordComponentService} from "./valio-update-password-component.service";
import {ActivatedRoute} from "@angular/router";
import {WindowRef} from "@spartacus/core";

@Component({
    selector: 'valio-cx-update-password-form',
    templateUrl: './valio-update-password-form.component.html',
    standalone: false
})
export class ValioUpdatePasswordFormComponent extends UpdatePasswordComponent implements OnInit {
  isSubmitClicked = false;

  constructor(
    protected service: ValioUpdatePasswordComponentService,
    protected activatedRoute: ActivatedRoute,
    protected winRef: WindowRef,
    protected renderer2: Renderer2,
  ) {
    super(service);
  }

  ngOnInit(): void {
    // Hide default header and footer for forced password change page
    if (this.activatedRoute.snapshot.queryParams.force === 'false') {
      const header = this.winRef.document.getElementsByTagName('header')[0];
      this.renderer2.addClass(header, 'd-none');

      const footer = this.winRef.document.getElementsByTagName('footer')[0];
      this.renderer2.addClass(footer, 'd-none');

      // wait a sec for logo
      setTimeout(() => {
        const logo = this.winRef.document.getElementsByClassName('SimpleHeader')[0];
        if (logo) {
          this.renderer2.addClass(logo, 'disabled');
        }
      }, 1000);
    }
  }

  isNotValid(formControlName: string): boolean {
    return FormUtils.isNotValidField(
      this.form,
      formControlName,
      this.isSubmitClicked
    );
  }

  isPasswordConfirmNotValid(): boolean {
    return (
      this.form.hasError('NotEqual') &&
      (this.isSubmitClicked ||
        (this.form.get('newPasswordConfirm').touched &&
          this.form.get('newPasswordConfirm').dirty))
    );
  }

  onSubmit(): void {
    this.isSubmitClicked = true;
    if (this.form.invalid) {
      return;
    }
    this.allowNormalHeader();
    super.onSubmit();
  }

  showCancel(): boolean {
    return this.activatedRoute.snapshot.queryParams.force === 'false';
  }

  allowNormalHeader(): void {
    const header = this.winRef.document.getElementsByTagName('header')[0];
    this.renderer2.removeClass(header, 'd-none');

    const footer = this.winRef.document.getElementsByTagName('footer')[0];
    this.renderer2.removeClass(footer, 'd-none');

    const logo = this.winRef.document.getElementsByClassName('SimpleHeader')[0];
    this.renderer2.removeClass(logo, 'disabled');
  }
}

