import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CmsParagraphComponent} from '@spartacus/core';
import {CmsComponentData} from "@spartacus/storefront";

@Component({
    selector: 'cx-paragraph',
    templateUrl: './paragraph.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ParagraphComponent {
  constructor(public component: CmsComponentData<CmsParagraphComponent>) {
  }
}
