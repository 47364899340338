import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageLayoutService, ProductListComponent, ViewConfig, ViewModes} from '@spartacus/storefront';
import {take} from 'rxjs/operators';
import {ValioProductListComponentService} from "./valio-product-list-component.service";
import {Subscription} from "rxjs";
import {GlobalMessageService} from "@spartacus/core";

@Component({
    selector: 'valio-cx-product-list',
    templateUrl: './valio-product-list.component.html',
    standalone: false
})
export class ValioProductListComponent extends ProductListComponent implements OnInit, OnDestroy {

    ViewModes = ViewModes;
    subscriptions: Subscription = new Subscription();

    constructor(protected lPageLayoutService: PageLayoutService,
                productListComponentService: ValioProductListComponentService,
                globalMessageService: GlobalMessageService,
                scrollConfig: ViewConfig
    ) {
        super(lPageLayoutService, productListComponentService, globalMessageService, scrollConfig);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.subscriptions.add(
            this.lPageLayoutService.templateName$.pipe(take(1)).subscribe(template => {
                this.setViewMode(
                    (template === 'ProductGridPageTemplate' || template === 'SearchResultsGridPageTemplate' || template === 'CartPageTemplate') ? ViewModes.Grid : ViewModes.List
                );
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
