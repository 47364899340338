import {Component, OnInit} from '@angular/core';
import {RoutingService, WindowRef} from '@spartacus/core';
import {Location} from '@angular/common';

@Component({
    selector: 'valio-cx-my-account-header',
    templateUrl: './valio-my-account-header.component.html',
    standalone: false
})
export class ValioMyAccountHeaderComponent implements OnInit {
  constructor(
    protected winRef: WindowRef,
    protected routingService: RoutingService,
    protected location: Location) {
  }

  ngOnInit(): void {
  }

  showMenu(): void {
    document.getElementById('my-account-menu').classList.toggle('is-open');
    const storefront = this.winRef.document.getElementsByTagName('valio-cx-storefront')[0];
    storefront.classList.add('is-expanded');
  }
}
