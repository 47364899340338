import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbDate,} from "@ng-bootstrap/ng-bootstrap";
import {Subscription} from "rxjs";
import {ValioUserService} from "../../../services/user/valio-user.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {DateUtils} from "../../misc/util/date-utils";


@Component({
    selector: 'valio-deliverynote-datepicker-form',
    templateUrl: './valio-deliverynote-datepicker-form.html',
    standalone: false
})
export class ValioDeliveryNoteDatepickerForm implements OnInit, OnDestroy {
  @Input()
  resetViewVisible: boolean;
  @Output()
  searchButtonEventEmitter = new EventEmitter<NgbDate>();
  @Output()
  resetViewEventEmitter = new EventEmitter<boolean>();
  @Input()
  dateId: string;
  @Input()
  labelPlaceholder: string;
  @Input()
  selectedDate: NgbDate;
  @Input()
  dateList: Date[];

  form: UntypedFormGroup;

  private subscription = new Subscription();

  constructor(protected userService: ValioUserService,
              protected fb: UntypedFormBuilder,
              protected cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.form =this.fb.group(
      {
        date: ['', [Validators.required]]
      }
    );
    if (this.selectedDate) {
      this.form.get('date').setValue(this.selectedDate);
    }
    this.loadInitialDates();
  }

  isDateInvalid() {
    return this.form.get('date').invalid && (this.form.get('date').dirty && this.form.get('date').touched);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  searchDate() {
    if (!this.isDateInvalid()) {
      this.resetViewVisible = true;
      this.searchButtonEventEmitter.emit(this.form.value.date);
    }
  }

  resetView() {
    this.resetViewVisible = false;
    this.resetViewEventEmitter.emit(this.resetViewVisible);
  }

  /* Load delivery notes for datepicker */
  loadInitialDates(): void {
    // By default, datepicker loads +-10 years
    const currentDate: Date = DateUtils.getNow();
    const beginDate: Date = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, currentDate.getDay());
    this.loadDateList(currentDate, beginDate);
  }

  loadDateList(beginDate: Date, endDate: Date) {
    //this.cdr.detectChanges();
  }

  /* Date picker arrow navigation event */
  dateNavigate(navigateEvent) {
    if (navigateEvent.next !== null && navigateEvent.next !== undefined && this.subscription !== undefined) {
      // Load 1 month to each side as buffer
      const beginDate: Date = new Date(navigateEvent.next.year, navigateEvent.next.month - 2, 1);
      const endDate: Date = new Date(navigateEvent.next.year, navigateEvent.next.month + 1, 0);
      this.loadDateList(beginDate, endDate);
    }
  }


  isDateNotInvalid() {
    return false;
  }

  containsDeliveryNotes(date: NgbDate) {
    // Use numbers for date comparision
    const checkDate: number = this.toDate(date).getTime();
    return this.dateList ? this.dateList.map(date1 => date1.getTime()).includes(checkDate) : false;
  }

  toDate(ngbDate: NgbDate) {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

}


