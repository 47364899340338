import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import {ValioImportFromFileSummaryComponent} from "./valio-import-from-file-summary.component";

export const IMPORT_FROM_FILE_SUMMARY = 'IMPORT_FROM_FILE_SUMMARY';
export const valioImportFromFileSummaryLayoutConfig: LayoutConfig = {
  launch: {
    IMPORT_FROM_FILE_SUMMARY: {
      inlineRoot: true,
      component: ValioImportFromFileSummaryComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
