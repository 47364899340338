import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import {ValioNewContractComponent} from "./valio-new-contract.component";

export const NEW_CONTACT:string  = 'NEW_CONTACT';

export const valioNewContactLayoutConfig: LayoutConfig = {
    launch: {
        NEW_CONTACT: {
            inlineRoot: true,
            component: ValioNewContractComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};
