import {inject, Injectable} from "@angular/core";
import {UpdatePasswordComponentService, USE_MY_ACCOUNT_V2_PASSWORD} from "@spartacus/user/profile/components";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CustomFormValidators} from "@spartacus/storefront";
import {ValioCustomFormValidators} from "../../../../shared/validators/valio-custom-form-validators";
import {UserPasswordFacade} from "@spartacus/user/profile/root";
import {
  AuthRedirectService,
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
} from '@spartacus/core';
@Injectable({
  providedIn: 'root',
})
export class ValioUpdatePasswordComponentService extends UpdatePasswordComponentService {

  form: UntypedFormGroup = new UntypedFormGroup(
    {
      oldPassword: new UntypedFormControl('', Validators.required),
      newPassword: new UntypedFormControl('', [
        Validators.required,
        ValioCustomFormValidators.passwordValidator,
      ]),
      newPasswordConfirm: new UntypedFormControl('', Validators.required),
    },
    {
      validators: CustomFormValidators.passwordsMustMatch(
        'newPassword',
        'newPasswordConfirm'
      ),
    }
  );

  // Override private field from super class
  private usingV2New:boolean  = inject(USE_MY_ACCOUNT_V2_PASSWORD);

  constructor(  protected userPasswordService: UserPasswordFacade,
                protected routingService: RoutingService,
                protected globalMessageService: GlobalMessageService,
                protected authRedirectService?: AuthRedirectService,
                protected authService?: AuthService) {
      super(userPasswordService, routingService, globalMessageService, authRedirectService, authService);
  }


  protected onSuccess(): void {
    this.globalMessageService.add(
      {
        key: this.usingV2New
          ? 'myAccountV2PasswordForm.passwordUpdateSuccess'
          : 'updatePasswordForm.passwordUpdateSuccess',
      },
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
    this.busy$.next(false);
    this.form.reset();
    // Clear validators for each control
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].clearValidators();
      this.form.controls[key].updateValueAndValidity();
    });
    this.form.clearValidators();
    this.form.updateValueAndValidity()
    // Mark the form as untouched and pristine
    this.form.markAsUntouched();
    this.form.markAsPristine();

  }
}
