import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {HttpErrorHandler, I18nModule, provideConfigFromMetaTags} from '@spartacus/core';
import {
  OutletRefModule,
  SpinnerModule,
  defaultCmsContentProviders,
  StorefrontComponentModule, BaseStorefrontModule, AppRoutingModule
} from '@spartacus/storefront';

import {AppComponent} from './app.component';
import {FeaturesModule} from "./features/features.module";
import {environment} from '../environments/environment';
import {extModules} from '../environments/modules';
// locales
import {AsyncPipe, registerLocaleData} from "@angular/common";

import localeEn from '@angular/common/locales/en';
import localeFi from '@angular/common/locales/fi';
import localeSv from '@angular/common/locales/sv';
import {APP_ID, CUSTOM_ELEMENTS_SCHEMA, NgModule, Provider} from '@angular/core';
import {ValioBrowserCheckModule} from "./features/cms-components/navigation/browserCheck/valio-browser-check.module";
import {GoogleTagManagerModule} from "angular-google-tag-manager";
import {ValioUnauthorizedErrorHandler} from "./shared/utils/handlers/valio-unauthorized.handler";
import {EffectsFeatureModule, EffectsModule} from "@ngrx/effects";
import {UserAccountFacade} from '@spartacus/user/account/root';
import {ValioUserAccountFacade} from "./services/user/valio-user-account.facade";
import {SpartacusModule} from "./spartacus/spartacus.module";


import {StoreModule} from "@ngrx/store";

import {CheckoutCostCenterAdapter} from "@spartacus/checkout/b2b/core";
import {OccCheckoutCostCenterAdapter} from "@spartacus/checkout/b2b/occ";
import {OccCheckoutAdapter} from "@spartacus/checkout/base/occ";
import {CheckoutPaymentConnector} from "@spartacus/checkout/base/core";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {ValioAppRoutingModule} from "./valio-app-routing.module";
import {ServiceWorkerModule} from "@angular/service-worker";

registerLocaleData(localeFi, 'fi');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeSv, 'sv');

export const facadeProviders: Provider[] = [

  ValioUserAccountFacade,
  {
    provide: UserAccountFacade,
    useExisting: ValioUserAccountFacade,
  }
];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  providers: [
    provideHttpClient(withFetch(), withInterceptorsFromDi()),

    defaultCmsContentProviders,
    facadeProviders,
    {
      provide: APP_ID,
      useValue: 'spartacus-app'
    },
    provideConfigFromMetaTags(),
    {
      provide: HttpErrorHandler,
      useExisting: ValioUnauthorizedErrorHandler,
      multi: true
    },
    /* {
       provide: ROUTER_CONFIGURATION,
       useValue: {
         scrollPositionRestoration: 'enabled'
       }
     },*/
    CheckoutPaymentConnector,
    OccCheckoutAdapter,
    OccCheckoutCostCenterAdapter,
    {
      provide: CheckoutCostCenterAdapter,
      useClass: OccCheckoutCostCenterAdapter,
    },
  ],
  imports: [
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    SpartacusModule,
    StorefrontComponentModule,
    BaseStorefrontModule,
    BrowserModule,
    OutletRefModule,
    //BrowserModule.withServerTransition({appId: 'spartacus-app'}),
    AppRoutingModule,
    ValioAppRoutingModule,
    FeaturesModule,
    I18nModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    extModules,
    SpinnerModule,
    ValioBrowserCheckModule,
    EffectsModule,
    EffectsFeatureModule,


    GoogleTagManagerModule.forRoot({
      id: environment.googleManager.id,
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
        maxAge: 25,
        connectInZone: true,
      })
      : [],
    SpinnerModule,
    AsyncPipe,
  ],
  bootstrap: [AppComponent]

})


export class AppModule {

}

