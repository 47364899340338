import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {ValioReviewSubmitComponent} from "../valio-review-submit.component";
import {ValioCartService} from "../../../../../services/cart/valio-cart.service";
import {RoutingService} from "@spartacus/core";
import {ValioCheckoutService} from "../../../../../services/checkout/valio-checkout.service";
import {ValioDatePipe} from "../../../../../services/pipes/valio-date.pipe";
import {ValioCartPaymentModeGroupedEntryWrapper} from "../../../../../services/cart/valio-cart.objects";
import {ValioReviewSubmitInvoiceComponent} from "../invoice/valio-review-submit-invoice.component";
import {openCloseSpinner} from "../../../../../services/util/valio-modals-utils";
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'valio-cx-review-submit-credit',
    templateUrl: './valio-review-submit-credit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioReviewSubmitCreditComponent extends ValioReviewSubmitComponent {
  buttonTextKey = 'checkout.header.approve';

  constructor(
    protected cartService: ValioCartService,
    protected routingService: RoutingService,
    protected checkoutService: ValioCheckoutService,
    protected cxDate: ValioDatePipe,
    @Inject(DOCUMENT) protected document: Document) {
    super(cartService, routingService, checkoutService, cxDate);
  }

  placeCreditOrder(date: string, group: ValioCartPaymentModeGroupedEntryWrapper): void {
    if (ValioReviewSubmitInvoiceComponent.isClosingTimeOk(group)) {
      this.checkoutService.placeCreditOrderForDate(date);
    } else {
      openCloseSpinner(this.document,false);
      this.buttonTextKey = 'checkout.header.closingTimePassed';
    }
  }

  goBack(): void {
    this.routingService.go('/checkout/shipping-address'); // <-- go back to previous location
  }
}
