import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ProductGridItemComponent, ProductListItemContext, ProductListItemContextSource} from "@spartacus/storefront";
import {ValioProduct} from "../../../../models";
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {Observable} from "rxjs";
import {CmsService} from "@spartacus/core";
import {DateUtils} from "../../../misc/util/date-utils";

@Component({
    selector: 'valio-cx-product-grid-item',
    templateUrl: './valio-product-grid-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ProductListItemContextSource,
        {
            provide: ProductListItemContext,
            useExisting: ProductListItemContextSource,
        },
    ],
    standalone: false
})
export class ValioProductGridItemComponent extends ProductGridItemComponent {
  @Input() declare product: ValioProduct;
  @Input() listName: string;
  partnerIcon$: Observable<any>;
  currentDay: Date;
  showDeliveryPeriod: boolean = false;

  @Output()
  initProductCarouselWidths = new EventEmitter<any>();

  constructor(protected cmsService: CmsService,
              protected cartService: ValioCartService,
              productListItemContextSource: ProductListItemContextSource) {
    super(productListItemContextSource);
  }

  ngOnInit() {
    this.initProductCarouselWidths.emit();
    if (!this.product.partnerSupplier.useBrandImages && !this.product.partnerSupplier.productLogo) {
      this.partnerIcon$ = this.cmsService.getComponentData<any>("Partner_Logo_" + this.product.partnerSupplier.code);
    }
    this.currentDay = DateUtils.getNow();
    this.currentDay.setHours(0, 0, 0, 0);
    if ((this.product.deliveryPeriodFrom && new Date(this.product.deliveryPeriodFrom) > this.currentDay) || (this.product.deliveryPeriodTo && new Date(this.product.deliveryPeriodTo) > this.currentDay)) {
      this.showDeliveryPeriod = true;
    }
  }

  log(images: any) {
    console.log(images);
  }
}
