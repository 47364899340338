import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {FormUtils} from "../misc/util/form-utils";


@Component({
    selector: 'valio-cx-input',
    templateUrl: './valio-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioInputComponent {
  @Input() labelPlaceholder: string;
  @Input() nameForm: string;
  @Input() inputValue: string;
  @Input() errorMessage: string;
  @Input() parentForm: UntypedFormGroup;
  @Input() submitClicked: boolean;
  @Input() typeInput: string;
  @Input() idName: string;
  @Input() extraInfoBottom: string;
  @Input() isNotValidFunction: boolean;
  @Output() afterBlur = new EventEmitter<any>();
  @Output() afterChange = new EventEmitter<any>();
  @Output() afterKeyPress = new EventEmitter<any>();

  constructor(private eRef: ElementRef) {
  }

  isNotValid(formControlName: string): boolean {
    if (this.parentForm != null) {
      return FormUtils.isNotValidField(
        this.parentForm,
        formControlName,
        this.submitClicked
      );
    } else {
      return true;
    }
  }

  hasValue(): boolean {
    if (this.parentForm) {
      return (this.parentForm.get(this.nameForm) && this.parentForm.get(this.nameForm).value && this.parentForm.get(this.nameForm).value !== '');
    } else if (this.inputValue) {
      return this.inputValue !== '';
    } else {
      return false;
    }
  }

  toggleVisibility(elId: string) {
    const input: HTMLInputElement = document.getElementById(elId) as HTMLInputElement;
    if (input.type === "password") {
      input.type = "text";
    } else {
      input.type = "password";
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    var node = (<HTMLElement>this.eRef.nativeElement);
    if (!node.classList.contains('input-label')) {
      node = node.querySelector('.input-label');
    }
    var input = node.querySelector('input');
    if (this.eRef.nativeElement.contains(event.target)) {
      node.classList.add('has-value');
      input.focus();
    } else {
      if (!this.hasValue()) {
        node.classList.remove('has-value');
        input.blur();
      }
    }
  }

  onBlur($event: FocusEvent) {
    this.afterBlur.emit($event);
  }

  onChange($event: any) {
    this.afterChange.emit($event);
  }

  onKeypress($event: Event) {
    this.afterKeyPress.emit($event);
  }
}
