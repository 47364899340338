import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Price} from "@spartacus/core";

@Component({
    selector: 'valio-checkout-header',
    templateUrl: './valio-checkout-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioCheckoutHeaderComponent implements OnInit {
  @Input() date: Date;
  @Input() totalItems: number;
  @Input() totalPrice: Price;
  @Input() totalPriceWithTax: Price;
  @Input() totalWeightInKg: string;
  @Input() status: string;
  constructor() {

  }

  ngOnInit(): void {
  }
}
