import {Component, OnInit} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {ValioCmsFaqComponent} from '../../../../models';
import {Observable} from "rxjs";

@Component({
    selector: 'valio-faq-component',
    templateUrl: './valio-faq.component.html',
    standalone: false
})
export class ValioFaqComponent implements OnInit{
  data$: Observable<ValioCmsFaqComponent>

  constructor(
    protected componentData: CmsComponentData<ValioCmsFaqComponent>
  ) {
  }

  ngOnInit(): void {
    this.data$ = this.componentData.data$;
  }

  showHideAnswer(sectionId: string) {
    const parent = document.getElementById(sectionId);
    parent.querySelector('.question-title').classList.toggle('collapsed');
    parent.querySelector('.answer-section').classList.toggle('d-none');
  }
}
