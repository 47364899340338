import {NgModule} from '@angular/core';
import {CartPageEventModule} from "@spartacus/cart/base/core";
import {WishListModule} from "@spartacus/cart/wish-list";
import {CheckoutCoreModule} from "@spartacus/checkout/base/core";
import {CheckoutOccModule} from "@spartacus/checkout/base/occ";
import {
  AnonymousConsentsModule,
  AuthModule,
  CostCenterModule,
  CostCenterOccModule,
  ExternalRoutesModule,
  ProductModule,
  ProductOccModule,
  provideFeatureToggles,
  UserModule,
  UserOccModule
} from "@spartacus/core";
import {OrderModule} from "@spartacus/order";
import {OrderCancellationModule, OrderDetailsModule, OrderHistoryModule, OrderReturnModule, ReturnRequestDetailModule, ReturnRequestListModule} from "@spartacus/order/components";
import {OrderOccModule} from "@spartacus/order/occ";
import {ProductVariantsModule} from "@spartacus/product/variants";
import {SmartEditModule} from "@spartacus/smartedit";
import {
  AnonymousConsentManagementBannerModule,
  AnonymousConsentsDialogModule,
  BannerCarouselModule,
  BannerModule,
  BreadcrumbModule,
  CategoryNavigationModule,
  CmsParagraphModule,
  ConsentManagementModule,
  FooterNavigationModule,
  HamburgerMenuModule,
  HomePageEventModule,
  LinkModule,
  LoginRouteModule,
  LogoutModule,
  MyCouponsModule,
  MyInterestsModule,
  NavigationEventModule,
  NavigationModule,
  NotificationPreferenceModule,
  PageTitleModule,
  PaymentMethodsModule,
  ProductCarouselModule,
  ProductDetailsPageModule,
  ProductFacetNavigationModule,
  ProductImagesModule,
  ProductIntroModule,
  ProductListingPageModule,
  ProductListModule,
  ProductPageEventModule,
  ProductReferencesModule,
  ProductSummaryModule,
  ProductTabsModule,
  ScrollToTopModule,
  SearchBoxModule,
  SiteContextSelectorModule,
  StockNotificationModule,
  TabParagraphContainerModule,
  UserComponentModule
} from "@spartacus/storefront";
import {PersonalizationModule} from "@spartacus/tracking/personalization";
import {UserAccountModule} from "@spartacus/user/account";
import {LoginFormModule, LoginModule, LoginRegisterModule} from '@spartacus/user/account/components';
import {UserProfileModule} from "@spartacus/user/profile";
import {AddressBookModule, CloseAccountModule, ForgotPasswordModule, RegisterComponentModule, ResetPasswordModule, UpdateEmailModule, UpdatePasswordModule, UpdateProfileModule} from "@spartacus/user/profile/components";
import {CartBaseFeatureModule} from "./features/cart/cart-base-feature.module";
import {CartImportExportFeatureModule} from './features/cart/cart-import-export-feature.module';
import {CartQuickOrderFeatureModule} from './features/cart/cart-quick-order-feature.module';
import {CartSavedCartFeatureModule} from './features/cart/cart-saved-cart-feature.module';
import {CheckoutFeatureModule} from './features/checkout/checkout-feature.module';
import {OrderFeatureModule} from './features/order/order-feature.module';
import {ProductImageZoomFeatureModule} from './features/product/product-image-zoom-feature.module';
import {ProductVariantsFeatureModule} from './features/product/product-variants-feature.module';
import {SmartEditFeatureModule} from './features/smartedit/smart-edit-feature.module';
import {StoreFinderFeatureModule} from './features/storefinder/store-finder-feature.module';
import {PersonalizationFeatureModule} from './features/tracking/personalization-feature.module';
import {UserFeatureModule} from './features/user/user-feature.module';


@NgModule({
  declarations: [],
  exports: [],
  providers: [
    provideFeatureToggles({
      a11yImproveContrast: false,
    }),
  ],
  imports: [
    // Auth Core
    AuthModule.forRoot(),
    LogoutModule,
    LoginRouteModule,
    // Basic Cms Components
    HamburgerMenuModule,
    SiteContextSelectorModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    TabParagraphContainerModule,
    BannerCarouselModule,
    CategoryNavigationModule,
    NavigationModule,
    FooterNavigationModule,
    BreadcrumbModule,
    // User Core,
    ScrollToTopModule,
    PageTitleModule,
    UserModule,
    UserOccModule,
    // User UI,
    AddressBookModule,
    PaymentMethodsModule,
    NotificationPreferenceModule,
    MyInterestsModule,
    StockNotificationModule,
    ConsentManagementModule,
    MyCouponsModule,
    // Anonymous Consents Core,
    AnonymousConsentsModule.forRoot(),
    // Anonymous Consents UI,
    AnonymousConsentsDialogModule,
    AnonymousConsentManagementBannerModule,
    // Product Core,
    ProductModule.forRoot(),
    ProductOccModule,
    // Product UI,
    ProductDetailsPageModule,
    ProductListingPageModule,
    ProductListModule,
    SearchBoxModule,
    ProductFacetNavigationModule,
    ProductTabsModule,
    ProductCarouselModule,
    ProductReferencesModule,
    ProductImagesModule,
    ProductSummaryModule,
    ProductIntroModule,
    // Cart UI,
    WishListModule,
    CostCenterOccModule,
    // Order,
    OrderOccModule,
    // Page Events,
    NavigationEventModule,
    HomePageEventModule,
    CartPageEventModule,
    ProductPageEventModule,
    // External routes,
    ExternalRoutesModule.forRoot(),
    UserFeatureModule,
    CartBaseFeatureModule,
    CartSavedCartFeatureModule,
    CartQuickOrderFeatureModule,
    CartImportExportFeatureModule,
    OrderFeatureModule,
    CheckoutFeatureModule,
    PersonalizationFeatureModule,
    StoreFinderFeatureModule,
    SmartEditFeatureModule,
    ProductVariantsFeatureModule,
    ProductImageZoomFeatureModule,
    OrderFeatureModule,
    CartSavedCartFeatureModule,
    CartQuickOrderFeatureModule,
    CartImportExportFeatureModule,
    OrderModule,
    AddressBookModule,
    AnonymousConsentsDialogModule,
    AnonymousConsentManagementBannerModule,
    AnonymousConsentsModule.forRoot(),

    BannerCarouselModule,

    BreadcrumbModule,
    CategoryNavigationModule,
    CloseAccountModule,
    CmsParagraphModule,
    ConsentManagementModule,
    CostCenterModule.forRoot(),
    CheckoutCoreModule,
    CheckoutOccModule,
    FooterNavigationModule,
    ForgotPasswordModule,
    LoginModule, LoginFormModule, LoginRegisterModule, RegisterComponentModule,
    MyCouponsModule,
    MyInterestsModule,
    NavigationModule,
    NotificationPreferenceModule,
    OrderCancellationModule,
    OrderDetailsModule,
    OrderHistoryModule,
    OrderReturnModule,
    PaymentMethodsModule,
    PersonalizationModule,


    ProductVariantsModule,
    //  QualtricsModule,
    //  ReplenishmentOrderConfirmationModule,
    //  ReplenishmentOrderDetailsModule,
    //   ReplenishmentOrderHistoryModule,
    ResetPasswordModule,
    ReturnRequestDetailModule,
    ReturnRequestListModule,

    SmartEditModule,
    StockNotificationModule,
    TabParagraphContainerModule,
    UpdateEmailModule,
    UpdatePasswordModule,
    UpdateProfileModule,
    UserComponentModule,
    UserAccountModule,
    UserProfileModule,
    UserOccModule,

  ]
})
export class SpartacusFeaturesModule {
}
