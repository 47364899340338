import {Component, Inject} from '@angular/core';
import {RoutingService,} from '@spartacus/core';
import {Observable} from 'rxjs';
import {ValioOrderHistoryList} from "../../../models/misc.model";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {map} from "rxjs/operators";
import {ValioDatePipe} from "../../../services/pipes/valio-date.pipe";
import {ValioDateRangeSearchComponent} from "../deliverynotes/valio-date-range-search.component";
import {DateUtils} from "../../misc/util/date-utils";
import {openCloseSpinner} from "../../../services/util/valio-modals-utils";
import {ValioUserService} from "../../../services/user/valio-user.service";
import {UserAccountFacade} from '@spartacus/user/account/root';
import {DOCUMENT} from "@angular/common";


@Component({
    selector: 'valio-cx-order-history',
    templateUrl: './valio-order-history.component.html',
    standalone: false
})
export class ValioOrderHistoryComponent extends ValioDateRangeSearchComponent {

  orderHistory$: Observable<ValioOrderHistoryList>;
  fullOrderHistory$: Observable<Date[]>;
  form: UntypedFormGroup;

  constructor(
    protected userService: ValioUserService,
    protected userAccountFacade: UserAccountFacade,
    protected fb: UntypedFormBuilder,
    protected cxDate: ValioDatePipe,
    protected routerService: RoutingService,
    protected route: ActivatedRoute,
    @Inject(DOCUMENT) protected document: Document
  ) {
    super(userAccountFacade, routerService, cxDate, route);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.form = this.fb.group(
      {
        poNumber: ['']
      }
    );
    this.setOrderHistoryList();
    const past = DateUtils.getNow();
    const future = DateUtils.getNow();
    past.setMonth(past.getMonth() - 6);
    future.setMonth(future.getMonth() + 1);
    this.fullOrderHistory$ = this.loadOrders(past, future, true).pipe(
      map(
        history => history.pastEntries.map(e => this.convertDate(e.deliveryDate))
      ));

  }

  setOrderHistoryList() {
    openCloseSpinner(this.document, true);
    this.orderHistory$ = this.loadOrders(this.fromDate, this.toDate, false);
  }

  searchDate(event: NgbDate, from: boolean) {
    openCloseSpinner(this.document, true);
    super.searchDate(event, from);
    const poNumber: string = this.form.value['poNumber'];
    //  this.routerService.go('my-account/orders/' + (this.cxDate.transform(this.fromDate, 'yyyy-MM-dd') + "/" + poNumber));
    this.setOrderHistoryList();
  }


  private loadOrders(from: Date, to: Date, metaDataOnly: boolean): Observable<ValioOrderHistoryList> {
    return this.userService.getOrderList(from, to, this.form.value['poNumber'], "desc", metaDataOnly);
  }

  resetView() {
    super.resetView();
    this.setOrderHistoryList();
  }

}
