import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";

import {ResetPasswordComponent} from "@spartacus/user/profile/components";
import {ValioUpdatePasswordComponentService} from "./valio-reset-password-component.service";


@Component({
    selector: 'valio-cx-reset-password',
    templateUrl: './valio-reset-password.component.html',
    standalone: false
})
export class ValioResetPasswordComponent extends ResetPasswordComponent implements OnInit, OnDestroy {
  token: string;
  isSubmitted: boolean;
  isSubmitted$: BehaviorSubject<boolean>;
  subscriptions: Subscription = new Subscription();

  constructor(service: ValioUpdatePasswordComponentService) {
    super(service);
    this.isSubmitted$ = service.isSubmitted$;

  }

  ngOnInit() {
    this.subscriptions.add(this.isSubmitted$.subscribe((value: boolean) => {
      this.isSubmitted = value
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  isPasswordInvalid(fieldName: string): boolean {
    return this.form.get(fieldName).invalid && (this.isSubmitted || (this.form.get(fieldName).dirty && this.form.get(fieldName).touched))
  }

  isPasswordConfirmInvalid(fieldName: string): boolean {
    return this.form.hasError('NotEqual') && (this.isSubmitted || (this.form.get(fieldName).dirty && this.form.get(fieldName).touched))
  }

  isInvalid(fieldName: string): boolean {
    return this.form.get(fieldName).invalid && (this.isSubmitted || this.form.get(fieldName).touched);
  }
}
