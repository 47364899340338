import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'productCode',
    standalone: false
})
export class ValioProductCodePipe implements PipeTransform {
  transform(code: string): string {
    return code != null ? code.replace(/[A-Za-z\-]+/g, '') : '';
  }
}
