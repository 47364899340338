import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoginFormComponent, LoginFormComponentService} from "@spartacus/user/account/components";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {AuthRedirectService, AuthService, GlobalMessageService, GlobalMessageType} from "@spartacus/core";
import {ValioCalendarDataService} from "../../../calendar/valio-calendar-data.service";
import {Subscription} from "rxjs";
import {UserAccountFacade} from '@spartacus/user/account/root';
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'valio-cx-login-form',
    templateUrl: './valio-login-form.component.html',
    standalone: false
})
export class ValioLoginFormComponent extends LoginFormComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  constructor(
    protected service: LoginFormComponentService,
    protected userAccountFacade: UserAccountFacade,
    protected calendarDataService: ValioCalendarDataService,
    protected activatedRoute: ActivatedRoute,
    protected _auth: AuthService,
    protected _globalMessageService: GlobalMessageService,
    protected _fb: UntypedFormBuilder,
    protected _authRedirectService: AuthRedirectService,
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.form = this._fb.group({
      userId: ['', Validators.required],
      password: ['', Validators.required],
    });
    const gtmUrlParam = this.activatedRoute.snapshot.queryParamMap.get('_gl');
    if (gtmUrlParam) {
      this._authRedirectService.setRedirectUrl('/?_gl=' + gtmUrlParam);
    }
    this.subscriptions.add(this._auth.isUserLoggedIn().subscribe(isLogged => {
      if (isLogged) {
        this._globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
        this._authRedirectService.redirect();
        this.calendarDataService.activateMiniCart(false);
      }
    }));

  }

  loginUser(): void {
    const {userId, password} = this.form.controls;
    // backend accepts lowercase emails only
    const uid = userId.value.toLowerCase()
    this._auth.loginWithCredentials(uid, password.value)
      .then(value => console.debug(`Logged in: ${uid}`));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
