import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LaunchDialogService} from "@spartacus/storefront";
import {ValioOrderTemplatesService} from "../../../../services/orderTemplates/valio-order-templates.service";

import {ValioCreateOrderTemplateDialogData} from "./valio-create-order-template-layout.config";
import {Subscription} from "rxjs";


@Component({
    selector: 'valio-cx-create-order-template',
    templateUrl: './valio-create-order-template.component.html',
    standalone: false
})
export class ValioCreateOrderTemplateComponent implements OnInit, OnDestroy {

  name: string;

  @Input() titleKey: string;
  @Input() buttonKey: string;
  @Input() copyId: string;
  @Input() copyDate: string;
  @Input() copyName: string;
  @Output() afterClose = new EventEmitter();

  private subscriptions: Subscription = new Subscription();

  constructor(
    protected orderTemplatesService: ValioOrderTemplatesService,
    protected launchDialogService: LaunchDialogService,
    protected cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.name = this.copyName;
    this.subscriptions.add(this.launchDialogService.data$.subscribe((data: ValioCreateOrderTemplateDialogData) => {
      this.titleKey = data.titleKey;
      this.buttonKey = data.buttonKey;
      this.copyId = data.copyId;
      this.copyName = data.copyName;
      this.copyDate = data.copyDate;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  dismissModal(): void {
    this.launchDialogService.closeDialog(null);
    //this.modalService.dismissActiveModal(reason);
  }

  create() {
    if (this.name) {
      this.orderTemplatesService.createOrderTemplate(this.name, this.copyId, this.copyDate);
      this.dismissModal();
      this.afterClose.emit();
    }
  }

  hasValue(): boolean {
    return this.name !== '';
  }
}
