import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root',
})
@Pipe({
    name: 'valioDate',
    standalone: false
})
export class ValioDatePipe extends DatePipe implements PipeTransform {
  constructor() {
    super('fi');
  }

  transform(value: any, format?: string, timezone?: string): any {
    return super.transform(value, format, timezone, 'fi');
  }
}
