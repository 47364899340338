import {Component} from '@angular/core';
import { LaunchDialogService} from "@spartacus/storefront";
import {CLOSE_ACCOUNT_MODAL} from "./close-account-modal/valio-close-account-modal-layout.config";
@Component({
    selector: 'valio-cx-close-account-profile',
    templateUrl: './valio-close-account.component.html',
    standalone: false
})
export class ValioCloseAccountComponent {

  constructor(protected launchDialogService: LaunchDialogService) {

  }


  openConfirm(): void {
    this.launchDialogService.openDialogAndSubscribe(CLOSE_ACCOUNT_MODAL, null);
  }


}
