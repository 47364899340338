import { InjectionToken, Provider } from "@angular/core";
import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { CartActions } from "@spartacus/cart/base/core";
import {AuthActions, StateUtils} from "@spartacus/core";

import { SELECT_UNIT } from "../user/valio-user-actions";
import { LOAD_ROUTE_CALENDAR, LOAD_ROUTE_CALENDAR_FAIL, LOAD_ROUTE_CALENDAR_SUCCESS, ValioLoadRouteCalendarSuccess, ValioRouteCalendarsState, ValioRouteCalendarState } from "./valio-routecalendar.action";
import {CheckoutActions} from "../../features/checkout/core/store";

export const initialState: ValioRouteCalendarState = {
  content: { routeDates: [] },
  loading: false
};

export const metaReducers: MetaReducer<any>[] = [clearState];

export const reducerToken: InjectionToken<ActionReducerMap<ValioRouteCalendarsState>> = new InjectionToken<ActionReducerMap<ValioRouteCalendarsState>>('RouteCalendarReducers');

export const valioRouteCalendarReducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getValioRouteCalendarReducers,
};

export function clearState(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function(state, action) {
    if (action.type === CartActions.CART_ADD_ENTRY_SUCCESS ||
      action.type === SELECT_UNIT ||
      action.type === CheckoutActions.PLACE_ORDER_SUCCESS ||
      action.type === AuthActions.LOGIN) {
      state = initialState;
    }
    return reducer(state, action);
  };
}

export function getValioRouteCalendarReducers(): ActionReducerMap<ValioRouteCalendarsState> {
  return {
    active: StateUtils.loaderReducer<ValioRouteCalendarState>(LOAD_ROUTE_CALENDAR_SUCCESS, valioRouteCalendarReducer),
  };
}

export function valioRouteCalendarReducer(
  state = initialState,
  action: StateUtils.LoaderAction
): ValioRouteCalendarState {
  const ret = { ...state };
  switch (action.type) {
    case LOAD_ROUTE_CALENDAR: {
      return {
        ...initialState,
        loading: true
      }
    }
    case LOAD_ROUTE_CALENDAR_SUCCESS: {
      return {
        ...state,
        content: (action as ValioLoadRouteCalendarSuccess).routeCal
      };
    }
    case LOAD_ROUTE_CALENDAR_FAIL: {
      return {
        ...state,
        content: {
          routeDates: [],
          activities: []
        }
      }
    }
  }
  return ret;
}
