import {Component, ElementRef} from '@angular/core';
import {KeyboardFocusService, StorefrontComponent} from "@spartacus/storefront";
import {Observable} from 'rxjs';
import {ValioUserService} from '../../../services/user/valio-user.service';
import {RoutingService} from '@spartacus/core';
import {ValioHamburgerMenuService} from '../hamburger-menu/valio-hamburger-menu.service';

@Component({
    selector: 'valio-cx-storefront',
    templateUrl: './valio-storefront.component.html',
    standalone: false
})
export class ValioStorefrontComponent extends StorefrontComponent {
  isAnonymous$: Observable<boolean>;
  isSignedIn$: Observable<boolean>;

  constructor(
    hamburgerMenuService: ValioHamburgerMenuService,
    routingService: RoutingService,
    elementRef: ElementRef<HTMLElement>,
    keyboardFocusService: KeyboardFocusService,
    protected userService: ValioUserService) {
    super(hamburgerMenuService, routingService, elementRef, keyboardFocusService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isAnonymous$ = this.userService.isAnonymous();
    this.isSignedIn$ = this.userService.isSignedIn();
  }
}
