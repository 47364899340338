import {Injectable} from '@angular/core';
import {RoutingService,} from '@spartacus/core';
import {Observable} from 'rxjs';
import {map,} from 'rxjs/operators';
import {LaunchDialogService} from "@spartacus/storefront";
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {ValioDatePipe} from "../../../../services/pipes/valio-date.pipe";
import {DateUtils} from "../../../misc/util/date-utils";
import {ValioLogoutDialogData} from "./valio-logout-modal-layout.config";
import {LOGOUT_MODAL} from "./valio-logout-modal-layout.config";

@Injectable({
  providedIn: 'root',
})
export class ValioLogoutPreviewGuard {
  static guardName = 'ValioLogoutPreviewGuard';
  currentDay: Date;

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected cartService: ValioCartService,
    protected routingService: RoutingService,
    protected cxDate: ValioDatePipe,
  ) {
  }

  canActivate(): Observable<boolean> {
    let deliveryDate:string =null
    return this.cartService.getActive().pipe(
      map(cart => {
        let canLogout = true;
        if (cart.dateGroupedEntries) {
          this.currentDay = DateUtils.getNow();
          const date = this.cxDate.transform(this.currentDay, 'yyyy-MM-dd');
          cart.dateGroupedEntries.forEach(entry => {
            if (entry.deliveryDate >= date && entry.validForOrdering) {
              canLogout = false;
              if(deliveryDate == null) {
                // Take the first incomplete order.
                deliveryDate = entry.deliveryDate
              }
            }
          });
        }
        if (canLogout) {
          this.routingService.go('logout');
        } else {
          this.launchDialogService.openDialogAndSubscribe(LOGOUT_MODAL, null, {
            logoutModal: true,
            unit: null,
            date: deliveryDate
          } as ValioLogoutDialogData);
        }
        return false;  // never actually open the page
      })
    );
  }

}
