import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";

import {RoutingService} from "@spartacus/core";
import {ValioCart} from "../../../../services/cart/valio-cart.objects";
import {ValioCheckoutService} from "../../../../services/checkout/valio-checkout.service";
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {filter} from "rxjs/operators";
import {ValioDatePipe} from "../../../../services/pipes/valio-date.pipe";

@Component({
    selector: 'valio-cx-review-submit',
    templateUrl: './valio-review-submit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioReviewSubmitComponent implements OnInit, OnDestroy {
  cart$: Observable<ValioCart>;
  requestedDate$: Observable<Date>;

  placeOrderSubscription: Subscription;

  constructor(
    protected cartService: ValioCartService,
    protected routingService: RoutingService,
    protected checkoutService: ValioCheckoutService,
    protected cxDate: ValioDatePipe
  ) {
  }

  ngOnInit(): void {
    this.requestedDate$ = this.cartService.getRequestedDate();
    this.cart$ = this.cartService.getActive();
    this.placeOrderSubscription = this.checkoutService
      .getOrderDetails()
      .pipe(filter(order => Object.keys(order).length !== 0))
      .subscribe(order => {
        this.routingService.go({cxRoute: 'orderConfirmation'}, {
          queryParams: {
            date: this.cxDate.transform(new Date((order as any).requestedDate), 'yyyy-MM-dd')
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.placeOrderSubscription.unsubscribe();
  }
}
