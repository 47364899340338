import {Directive, Input, OnDestroy, TemplateRef, ViewContainerRef} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Directive({
    selector: '[hideOnLocation]',
    standalone: false
})
export class ValioHideOnLocationDirective implements OnDestroy {
  private hasView: boolean;
  subscriptions: Subscription = new Subscription();

  @Input() set hideOnLocation(url: string) {
    this.subscriptions.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (event.url.indexOf(url) !== -1 && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
          } else if (event.url.indexOf(url) === -1 && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
          }
        }
      })
    );
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  constructor(
    protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef,
    protected router: Router) {
  }
}
