import {ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {RoutingService} from "@spartacus/core";
import {LaunchDialogService} from "@spartacus/storefront";
import {openCloseSpinner} from "../../../../services/util/valio-modals-utils";
import {ValioLogoutDialogData} from "./valio-logout-modal-layout.config";
import {Subscription} from "rxjs";
import {ValioUserService} from "../../../../services/user/valio-user.service";
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'valio-cx-logout-modal',
    templateUrl: './valio-logout-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioLogoutModalComponent implements OnInit, OnDestroy {
  @Input() logoutModal: boolean = true;

  unit: string;
  date: string;

  private subscriptions: Subscription = new Subscription();

  constructor(private routingService: RoutingService,
              protected launchDialogService: LaunchDialogService,
              protected userService: ValioUserService,
              protected cartService: ValioCartService,
              @Inject(DOCUMENT) protected document: Document) {

  }

  ngOnInit(): void {
    this.subscriptions.add(this.launchDialogService.data$
      .subscribe((dialogData: ValioLogoutDialogData) => {
        this.logoutModal = dialogData.logoutModal;
        this.unit = dialogData.unit;
        this.date = dialogData.date;
      }))
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  logout(): void {
    openCloseSpinner(this.document, true);
    this.launchDialogService.closeDialog(null);
    this.routingService.go('logout');
  }

  switchUnit(): void {
    openCloseSpinner(this.document, true);
    if (this.unit) {
      this.userService.selectUnit(this.unit);
    }
    this.launchDialogService.closeDialog(null)
  }

  goToOrders() {
    if(this.date != null) {
      // Change date to first incomplete order date.
      this.cartService.changeDate(this.date);
    }
    this.routingService.go('/cart');
    this.launchDialogService.closeDialog(null)
  }

  dismissModal():void {
    this.launchDialogService.closeDialog(null)
  }
}
