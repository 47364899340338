import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output
} from '@angular/core';
import {ValioCart, ValioCartPaymentModeGroupedEntryWrapper} from "../../../../../services/cart/valio-cart.objects";
import {Price} from "@spartacus/core";
import {openCloseSpinner} from "../../../../../services/util/valio-modals-utils";
import {ValioGoogleAnalyticsService} from "../../../../../services/analytics/valio-google-analytics.service";
import {DOCUMENT} from "@angular/common";


@Component({
    selector: 'valio-cart-total-bar-rows',
    templateUrl: './valio-cart-total-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioCartTotalBarComponent {
  @Input() cart: ValioCart;
  @Input() group: ValioCartPaymentModeGroupedEntryWrapper;
  @Input() showButton: boolean;
  @Input() loggedUser: boolean;
  @Input() buttonTextKey: string;
  @Input() totalItems: number;
  @Input() totalPrice: Price;
  @Input() subtotal: Price;
  @Input() totalPriceWithTax: Price;
  @Input() totalPriceWithoutTax: Price;
  @Input() freightCharge: Price;
  @Input() variableWeightPaymentCost: Price;

  @Input() buttonEnabled: boolean;

  @Output()
  clickButton = new EventEmitter<any>();

  constructor(protected cdr: ChangeDetectorRef,
              protected googleAnalyticsService: ValioGoogleAnalyticsService,
              @Inject(DOCUMENT) protected document: Document) {
  }

  click() {
    openCloseSpinner(this.document, true);
    this.googleAnalyticsService.purchaseEvent(this.cart);
    this.clickButton.emit(this.cart.requestedDate);
    this.buttonEnabled = false;
    this.buttonTextKey = 'checkout.header.wait';
    this.cdr.detectChanges();
  }


}
