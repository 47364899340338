import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'valio-cx-product-price',
    templateUrl: './valio-product-price.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioProductPriceComponent {
  @Input() product;
  @Input() isSummary = false;

  constructor() {

  }
}
