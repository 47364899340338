import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ValioUser, Vendor} from "../../../../models/misc.model";
import {ValioUserService} from "../../../../services/user/valio-user.service";
import {Observable} from "rxjs";


@Component({
    selector: 'valio-cx-product-partner',
    templateUrl: './valio-product-partner.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioProductPartnerComponent implements OnInit {

  @Input() selectedPartner: Vendor;

  constructor(protected userService: ValioUserService) {
  }

  ngOnInit(): void {
     }

  isContractCustomer(): Observable<boolean>{
   return this.userService.isContractCustomer()
  }
}
