import {ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, Renderer2,} from '@angular/core';
import {BreakpointService, LaunchDialogService, ProductFacetNavigationComponent} from '@spartacus/storefront';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {ValioFacet} from '../../../../../models';
import {ValioProductListComponentService} from "../../container/valio-product-list-component.service";
import {DOCUMENT, Location} from "@angular/common";
import {Facet, ProductSearchPage, WindowRef} from "@spartacus/core";
import {map, tap} from "rxjs/operators";
import { HttpUrlEncodingCodec } from "@angular/common/http";

const OPEN_FACET_QUERY = ':openFacet:';

@Component({
    selector: 'valio-product-facet-list',
    templateUrl: './valio-product-facet-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioProductFacetListComponent extends ProductFacetNavigationComponent implements OnDestroy {
  minPerFacet = 50;
  facets$: Observable<ValioFacet[]>;
  selectedOptions: Map<string, FacetOptions>;
  visibleFacets$: Observable<Facet[]>;
  searchResult$: Observable<ProductSearchPage>;
  showAllPerFacetMap: Map<String, boolean>;
  protected queryCodec: HttpUrlEncodingCodec;
  private sub: Subscription;
  activeFacetValueCode: string;
  private collapsedFacets = new Set<string>();
  subscriptions: Subscription = new Subscription();

  @Input() selectedPartner: string;

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected lactivatedRoute: ActivatedRoute,
    protected lproductListComponentService: ValioProductListComponentService,
    protected router: Router,
    protected  location: Location,
    protected breakpointService: BreakpointService,
    protected renderer: Renderer2,
    protected winRef: WindowRef,
    @Inject(DOCUMENT) protected document: Document

  ) {
    super(breakpointService);
    this.showAllPerFacetMap = new Map<String, boolean>();
    this.queryCodec = new HttpUrlEncodingCodec();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.lactivatedRoute.params.subscribe(params => {
        this.activeFacetValueCode = params.categoryCode || params.brandCode;
      })
    );

    this.searchResult$ = this.lproductListComponentService.model$.pipe(
      tap(searchResult => {
        if (searchResult.facets) {
          searchResult.facets.forEach(el => {
            this.showAllPerFacetMap.set(el.name, false);
          });
        }
      })
    );

    this.visibleFacets$ = this.searchResult$.pipe(
      map(searchResult => {
        return searchResult.facets
          ? searchResult.facets.filter(facet => facet.visible)
          : [];
      })
    );
    this.facets$ = this.visibleFacets$;
    this.setSelectedOptions(this.lactivatedRoute.snapshot.queryParamMap);
    this.subscriptions.add(
      this.lactivatedRoute.queryParamMap.subscribe(params => this.setSelectedOptions(params))
    );

// TODO:Spartacus - Getter method of property 'window' was removed from 'BreakpointService'. Instead use 'WindowRef' directly.
    this.document.defaultView.window.onclick = e => {
      const target = (<Element>e.target);
      if (!target.classList.contains('facet-name') && !target.classList.contains('cx-facet-list')) {
        this.toggleFacetList('');
      }
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  isFacetActive(facet: string): boolean {
    return this.selectedOptions.get(facet) != null;
  }

  isPreOpenFacet(facet: string) {
    return (this.selectedOptions.get(facet) ? this.selectedOptions.get(facet).isOpen : 0);
  }

  setSelectedOptions(queryParams: ParamMap): void {
    this.selectedOptions = new Map<string, FacetOptions>();
    if (queryParams['params'].query) {
      const urlSplit = queryParams['params'].query.indexOf(OPEN_FACET_QUERY) >= 0 ? queryParams['params'].query.split(OPEN_FACET_QUERY)[0] : null;
      const split = queryParams['params'].query.split(':');
      const newUrl = queryParams['params'].query;

      for (let i = 2; i < split.length - 1; i += 2) {
        this.setSelectedOption(split[i], urlSplit != null, urlSplit ? urlSplit : queryParams['params'].query)
      }
      if (urlSplit != null) {
        this.location.go(this.lactivatedRoute.snapshot.url[0].path.concat('?query=').concat(urlSplit)); // do not reopen facet window
      }
    }
  }

  setSelectedOption(facet: string, isOpen: boolean, queryString: string) {
    if (queryString) {
      const regexp = new RegExp(facet, 'g');
      const count = (queryString.match(regexp) || []).length;
      if (count > 0) {
        this.selectedOptions.set(facet, {count: count, isOpen: isOpen} as FacetOptions);
      }
    }
  }

  toggleValueForFacet(query: string, facet: string): void {
    this.lproductListComponentService.setQuery(
      this.queryCodec.decodeValue(query + OPEN_FACET_QUERY + facet)
    );
  }

  toggleFacetList(facet: string) {
    const facetList = document.getElementById('facetList_' + facet);
    if (facetList) {
      facetList.classList.toggle('active-facet');
      document.getElementById('facetName_' + facet).classList.toggle('facet-open');
    }
    //close all other filters
    let facetLists = document.getElementsByClassName('active-facet');
    let facets = document.getElementsByClassName('facet-open');
    for (let i = 0; i < facetLists.length; i++) {
      if (facetLists[i] && facetLists[i].id !== ('facetList_' + facet)) {
        facetLists[i].classList.remove('active-facet');
      }
    }
    for (let i = 0; i < facets.length; i++) {
      if (facets[i] && facets[i].id !== ('facetName_' + facet)) {
        facets[i].classList.remove('facet-open');
      }
    }
  }

  getSelectedOptions(code: string): number {
    return (this.selectedOptions.get(code) ? this.selectedOptions.get(code).count : 0);
  }

  isFacetCollapsed(facetName: string): boolean {
    return this.collapsedFacets.has(facetName);
  }

  toggleFacet(facetName: string): void {
    if (this.collapsedFacets.has(facetName)) {
      this.collapsedFacets.delete(facetName);
    } else {
      this.collapsedFacets.add(facetName);
    }
  }

  getVisibleFacetValues(facet): any {
    return facet.values.slice(
      0,
      this.showAllPerFacetMap.get(facet.name)
        ? facet.values.length
        : this.minPerFacet
    );
  }

  showLess(facetName: String): void {
    this.updateShowAllPerFacetMap(facetName, false);
  }

  showMore(facetName: String): void {
    this.updateShowAllPerFacetMap(facetName, true);
  }

  private updateShowAllPerFacetMap(facetName: String, showAll: boolean): void {
    this.showAllPerFacetMap.set(facetName, showAll);
  }

  toggleValue(query: string): void {
    this.lproductListComponentService.setQuery(
      this.queryCodec.decodeValue(query)
    );
  }

  launch() {
    this.renderer.addClass(document.body, 'modal-open');
    super.launch();
  }

  close() {
    this.renderer.removeClass(document.body, 'modal-open');
    super.close();
  }
}

interface FacetOptions {
  count: number;
  isOpen: boolean;
}
