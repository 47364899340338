import {Component} from '@angular/core';
import {UpdatePasswordComponent} from "@spartacus/user/profile/components";

@Component({
    selector: 'valio-cx-update-password',
    templateUrl: './valio-update-password.component.html',
    standalone: false
})
export class ValioUpdatePasswordComponent extends UpdatePasswordComponent{
}
