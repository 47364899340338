import {Component, OnInit} from '@angular/core';
import {UpdateProfileComponent} from '@spartacus/user/profile/components';
import {Observable} from "rxjs";
import {User} from "@spartacus/user/account/root";
import {ValioUpdateProfileComponentService} from "./valio-update-profile.component.service";

@Component({
    selector: 'valio-cx-update-profile',
    templateUrl: './valio-update-profile.component.html',
    standalone: false
})
export class ValioUpdateProfileComponent extends UpdateProfileComponent implements OnInit {

  user$: Observable<User>;
  constructor(
    protected service: ValioUpdateProfileComponentService
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.user$= this.service.user$;
  }

}
