import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {OccEndpointsService} from "@spartacus/core";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OccPostalCodeAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {}

  validPostalCode(postalCode: string): Observable<boolean> {
 const url = this.occEndpoints.buildUrl("postalCode", {queryParams: {code: postalCode}});
    return this.http.get<boolean>(url);
  }

  validAnonymousPostalCode(postalCode: string): Observable<boolean> {
   const url = this.occEndpoints.buildUrl("postalCode/anonymous", {queryParams: {code: postalCode}});
    return this.http.get<boolean>(url);
  }

}
