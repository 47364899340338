import {Component} from '@angular/core';

@Component({
    selector: 'valio-frontpage-component',
    templateUrl: './valio-frontpage.component.html',
    standalone: false
})
export class ValioFrontpageComponent {

}
