import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ValioCartEntry, ValioCartEntryUpdateData} from "../../../../../services/cart/valio-cart.objects";
import {ValioDatePipe} from "../../../../../services/pipes/valio-date.pipe";
import {ValioGoogleAnalyticsService} from "../../../../../services/analytics/valio-google-analytics.service";
import {interval, Subscription} from "rxjs";
import {distinctUntilChanged, map, tap} from "rxjs/operators";
import {DateUtils} from "../../../../misc/util/date-utils";



@Component({
    selector: 'valio-cx-cart-item',
    templateUrl: './valio-cart-item.component.html',
    standalone: false
})
export class ValioCartItemComponent implements OnDestroy{
  @Input()
  compact = false;
  @Input()
  hidePrices = false;
  @Input()
  item: ValioCartEntry;
  @Input()
  potentialProductPromotions: any[];
  @Input()
  isReadOnly = false;
  @Input()
  allowDelete = true;
  @Input()
  cartIsLoading = false;
  @Output()
  update = new EventEmitter<any>();
  @Output()
  view = new EventEmitter<any>();

  @Input()
  parent: UntypedFormGroup;

  @Input()
  listName: string;

  closingTimePassed: boolean;
  closingTimeAboutToPass: boolean;
  private subscription: Subscription = new Subscription();
  constructor(
    protected cxDate: ValioDatePipe,
    protected googleAnalyticsService: ValioGoogleAnalyticsService,
    protected cdr: ChangeDetectorRef
  ) {
    this.setClosingTimeTimer();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setClosingTimeTimer() {
    this.subscription.add(interval(1000)
      .pipe(
        map(() => DateUtils.getNow()),
        distinctUntilChanged((a: Date, b: Date) => a.getMinutes() === b.getMinutes()),
        tap(date => {
          if (this.convertDate(this.item.salesendDate).getTime() - (5 * 60 * 1000) < date.getTime()) {//5 mins before show alarm
            this.closingTimeAboutToPass = true;
            this.cdr.detectChanges();
          }
          if (this.convertDate(this.item.salesendDate).getTime() < date.getTime()) {
            this.closingTimeAboutToPass = false;
            this.closingTimePassed = true;
            this.cdr.detectChanges();
          }
        })
      ).subscribe());
  }

  getIsReadOnly() {
    return !this.item.editable || this.isReadOnly;
  }

  isProductOutOfStock(product) {
    // TODO Move stocklevelstatuses across the app to an enum
    return (
      product &&
      product.stock &&
      product.stock.stockLevelStatus === 'outOfStock'
    );
  }

  updateEntryData(data: ValioCartEntryUpdateData) {
    data.externalOrderNumber = this.item.externalOrderNumber;
    data.externalItemNumber = this.item.externalItemNumber;
    const addedAmount: number = data.qty - this.item.quantity;
    this.googleAnalyticsService.cartEvent(this.item.product, addedAmount);
    this.update.emit({item: this.item, data: data});
  }

  updatePoNumber(poNumber: string) {
    this.updateEntryData({poNumber: poNumber, qty: this.item.quantity} as ValioCartEntryUpdateData);
  }


  removeItem($event: MouseEvent) {
    this.updateEntryData({qty: 0} as ValioCartEntryUpdateData);
  }

  viewItem() {
    this.view.emit();
  }

  convertDate(date: string): Date {
    return DateUtils.convertDate(date);
  }

  updateSchoolMilkSupport(schoolMilkSupport: boolean) {
    this.updateEntryData({schoolMilkSupport: schoolMilkSupport, qty: this.item.quantity} as ValioCartEntryUpdateData);
  }

}
