import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

import {ValioBaseorderBlockModalComponent} from "./valio-baseorder-block-modal.component";

export const BASE_ORDER_BLOCK_MODAL:string  = 'BASE_ORDER_BLOCK_MODAL';
export const valioBaseOrderBlockModalLayoutConfig: LayoutConfig = {
    launch: {
        BASE_ORDER_BLOCK_MODAL: {
            inlineRoot: true,
            component: ValioBaseorderBlockModalComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};
