import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {ValioBoughtTogetherService} from "../../../../../services/recommendations/bought-together/valio-bought-together.service";
import {ProductSearchPage} from "@spartacus/core";
import {ValioUserService} from "../../../../../services/user/valio-user.service";
import {ValioProductSearchConnector} from "../../../../../services/product/valio-product-search.connector";

@Component({
    selector: 'valio-bought-together',
    templateUrl: 'valio-bought-together.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioBoughtTogetherComponent implements OnInit, OnDestroy {
  isAnonymous$: Observable<boolean>;
  isSignedIn$: Observable<boolean>;
  model$: Observable<ProductSearchPage>;
  size$: Observable<number>;

  userId: string;
  cartId: string;
  subscriptions: Subscription = new Subscription();

  @Input() title: string;
  @Input() showScroll: string;

  constructor(protected recommendationsService: ValioBoughtTogetherService,
              protected userService: ValioUserService,
              protected productSearchConnector: ValioProductSearchConnector) {
  }

  ngOnInit(): void {
    this.model$ = this.recommendationsService.getBoughtTogether();
    this.isAnonymous$ = this.userService.isAnonymous();
    this.isSignedIn$ = this.userService.isSignedIn();
    this.size$ = this.model$.pipe(
      map(m => m.products ? m.products.length : 0)
    );

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
