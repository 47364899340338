import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild,} from '@angular/core';
import {CmsComponentData, SearchBoxComponent, SearchBoxConfig} from "@spartacus/storefront";
import {Observable, Subject, Subscription} from "rxjs";
import {ValioProduct} from "../../../../models";
import {CmsSearchBoxComponent, RoutingService, WindowRef} from "@spartacus/core";
import {Vendor} from "../../../../models/misc.model";
import {ValioSuggestiveSearchBoxConfig} from "./valio-suggestive-search-box.module";
import {ValioSearchBoxComponentService} from "../../cart/cart-detail/suggestive-search/valio-search-box-component.service";
import {ValioSearchResults} from "../../../../services/product/valio-searchbox.service";
import {debounceTime} from "rxjs/operators";

const DEFAULT_SEARCHBOX_CONFIG: ValioSuggestiveSearchBoxConfig = {
  minCharactersBeforeRequest: 1,
  displayProducts: true,
  displaySuggestions: false,
  maxProducts: 20,
  maxSuggestions: 0,
  displayProductImages: false,
  showPrices: false,
  selectedPartner: null
};

@Component({
    selector: 'valio-suggestive-cx-searchbox',
    templateUrl: './valio-suggestive-search-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class ValioSuggestiveSearchBoxComponent extends SearchBoxComponent implements OnInit, OnDestroy {
  private selectedPartner: Vendor;
  product: ValioProduct;
  @Input() baseOrderMode = false;
  @Input() templateMode = false;
  @Input() frontPage = false;
  @Input() value: string = "";
  @Input() limit: number = 100;
  @Input() globalSearch: boolean = false;
  @Input() searchCategories: boolean = false;
  @Input() isInvalid: boolean = false;
  @Input() filterQuery: string;

  @Input() set setSelectedPartner(partner: Vendor) {
    this.selectedPartner = partner;
  }

  tooltipSubject: Subject<string> = new Subject<string>();
  tooltipIndex: number;

  @Output()
  onSelect = new EventEmitter<ValioProduct>();
  @Output()
  addToCartEmitter: EventEmitter<void> = new EventEmitter();

  searchResults$: Observable<ValioSearchResults>;
  config: SearchBoxConfig = DEFAULT_SEARCHBOX_CONFIG;

  @ViewChild('searchInput', {static: false})
  declare searchInput: ElementRef<HTMLInputElement>;

  searchInputSubject = new Subject<KeyboardEvent>()

  resultActive = false;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected routingService: RoutingService,
    protected searchBoxComponentService: ValioSearchBoxComponentService,
    winRef: WindowRef,
    @Optional()
    protected componentData: CmsComponentData<CmsSearchBoxComponent>
  ) {
    super(searchBoxComponentService, componentData, winRef, routingService);
  }

  ngOnInit(): void {
    this.config.maxProducts = this.limit;
    this.searchResults$ = this.searchBoxComponentService.getResults(this.config);

    //init search
    this.subscriptions.add(
      this.searchInputSubject.pipe(
        debounceTime(300)
      ).subscribe($event => {
        const query = this.searchInput.nativeElement.value;
        if (query == "") {
          this.hideTooltip();
        }
        this.doSearch(query + (this.filterQuery ? this.filterQuery : ''));
      })
    );
  }

  ngOnDestroy(): void {
    this.searchBoxComponentService.clearResults();
    this.subscriptions.unsubscribe();
  }

  searchEvent($event: KeyboardEvent) {
    const query = this.searchInput.nativeElement.value;
    if ($event.key == 'Enter' || $event.key == 'NumpadEnter' || $event.key == 'Tab' || $event.key == 'Escape' || $event.key == 'ArrowDown' || $event.key == 'ArrowUp') {
      if ($event.key == 'Enter' || $event.key == 'NumpadEnter' || $event.key == 'Tab') {
        if (!this.frontPage) {
          this.addProductLooper($event, query);
        } else if (this.selectedProductAnchor != null) {
          const tooltip = document.getElementById("product-tooltip-templ") as HTMLElement;
          const cartBtn = tooltip.querySelector(".btn-cart") as HTMLElement;
          if (cartBtn) {
            cartBtn.click();
          }
        } else {
          this.launchSearchResult($event, query);
          this.clearSearch();
        }
      } else if ($event.key == 'Escape') {
        this.clearSearch();
      } else if ($event.key == 'ArrowDown' || $event.key == 'ArrowUp') {
        this.activateProduct($event, false);
      }
    } else {
      this.resultActive = true;
      this.searchInputSubject.next($event);
    }
  }

  doSearch(query: string) {
    this.selectedProductAnchor = null;
    this.searchBoxComponentService.search(query,
      {
        ...this.config,
        selectedPartner: this.selectedPartner,
        baseOrderMode: this.baseOrderMode,
        searchCategories: this.searchCategories,
        globalSearch: this.globalSearch
      } as ValioSuggestiveSearchBoxConfig);
    // force the searchbox to open
    this.open();
  }

  open(): void {
    this.resultActive = true;
    super.open();
    this.cdr.detectChanges();
  }

  addProductLooper($event: KeyboardEvent, query: string) {    // loop to make sure the search ajax has triggered successfully
    if (this.templateMode) {
      setTimeout(() => {
        if (document.getElementsByClassName('search-result-anchor')?.length > 0) {
          $event.preventDefault();
          this.activateProduct({key: this.selectedProductAnchor == null ? 'ArrowDown' : 'Enter'} as KeyboardEvent, true);// select first product
          this.clearSearch();
          this.focusQtyField();
        } else {
          this.addProductLooper($event, query);
        }
      }, 100);
    } else {
      $event.preventDefault();
      this.activateProduct(null, true);// select first product
      this.clearSearch();
      this.focusQtyField();
    }
  }


  focusQtyField() {
    const qty = document.getElementById('itemCounterInput') as HTMLInputElement;
    qty.focus();
    qty.select();
  }

  selectProduct(product: ValioProduct) {
    this.selectedProductAnchor = null;
    if (!this.frontPage) {
      this.onSelect.emit(product);
    } else {
      this.routingService.go('/p/' + product.code);
    }
    this.clearSearch();
  }

  clearSearch() {
    this.selectedProductAnchor = null;
    this.searchBoxComponentService.clearResults();
    this.hideTooltip();
    this.close(null);
    this.resultActive = false;
    this.cdr.detectChanges();
  }

  clearSearchBox() {
    this.searchInput.nativeElement.value = '';
    this.value = '';
    this.clearSearch();
  }

  tooltipLooper(top: number) {    // loop to make sure tooltip exist
    setTimeout(() => {
      const tooltip = document.getElementById("product-tooltip-templ") as HTMLElement;
      if (tooltip) {
        tooltip.setAttribute("style", "top: " + top + "px;");
      } else {
        this.tooltipLooper(top);
      }
    }, 100);
  }

  showTooltip(product: ValioProduct, index: number) {
    if (this.tooltipIndex != index) {
      this.tooltipIndex = index;
      this.tooltipSubject.next(product.code);
      const anchors = document.getElementsByClassName(this.getAnchorClassName());
      if (anchors.length > index) {
        const currentItem = anchors[index] as HTMLElement;
        this.selectedProductAnchor = currentItem;
        const resultWrapper = document.getElementById("result-wrapper") as HTMLElement;
        this.tooltipLooper(resultWrapper.offsetTop + currentItem.offsetTop - currentItem.offsetParent.scrollTop);
      }
    }
  }

  hideTooltip() {
    this.tooltipSubject.next(null);
    this.tooltipIndex = null;
    const tooltipCloseBtn = document.getElementById("product-tooltip-close") as HTMLElement;
    if (tooltipCloseBtn) {
      tooltipCloseBtn.click();
    }
  }

  disableClose(): void {
  }

  selectedProductAnchor: HTMLElement;

  activateProduct($event: KeyboardEvent, selectProduct: boolean) {
    const anchors = document.getElementsByClassName(this.getAnchorClassName());
    let next: HTMLElement;
    if ($event?.key == 'ArrowDown') {
      next = this.selectedProductAnchor ? this.selectedProductAnchor.nextSibling as HTMLElement : anchors.length > 0 ? anchors[0] as HTMLElement : null;
    } else if ($event?.key == 'ArrowUp') {
      next = this.selectedProductAnchor ? this.selectedProductAnchor.previousSibling as HTMLElement : anchors.length > 0 ? anchors[0] as HTMLElement : null;
    }
    if (this.selectedProductAnchor && !next) {
      next = anchors[this.selectedProductAnchor.attributes['data-index'].value] as HTMLElement;
    }

    if (next) {
      if (!next.classList) {
        this.selectedProductAnchor = null;
        next = null;
      } else {
        const offsetParent: HTMLElement = <HTMLElement>next.offsetParent;
        if (offsetParent) {
          if (next.offsetTop > (offsetParent.offsetHeight + offsetParent.scrollTop)) { // scroll results-wrapper list if needed
            offsetParent.scrollTop += next.offsetHeight;
          } else if (next.offsetTop < offsetParent.scrollTop) {
            offsetParent.scrollTop -= next.offsetHeight;
          }
        }
        this.selectedProductAnchor = next;
        this.onSelect.emit(JSON.parse(next.attributes['data-product'].value));
        if (!this.frontPage) {
          this.hideTooltip();
        } else {
          this.showTooltip(JSON.parse(next.attributes['data-product'].value), JSON.parse(next.attributes['data-index'].value));
        }
      }
    } else if (selectProduct) {
      this.onSelect.emit({code: this.searchInput.nativeElement.value});
    }
  }

  getAnchorClassName(): string {
    return 'search-result-anchor';
  }

  isActive(index): boolean {
    return this.selectedProductAnchor ? this.selectedProductAnchor.attributes['data-index'].value == index : false;
  }

  tooltipClosed() {
    this.selectedProductAnchor = null;
    this.hideTooltip();
  }
}
