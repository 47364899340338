import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from "rxjs";
import {ValioUser} from "../../../models/misc.model";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {RoutingService} from "@spartacus/core";
import {ActivatedRoute} from "@angular/router";
import {ValioDatepickerInputComponent} from "./datepicker/valio-datepicker-input.component";
import {ValioDatePipe} from "../../../services/pipes/valio-date.pipe";
import {DateUtils} from "../../misc/util/date-utils";
import {map} from "rxjs/operators";
import {UserAccountFacade} from '@spartacus/user/account/root';

export const weekInMillis = 7 * 24 * 60 * 60 * 1000;

@Component({
    selector: 'valio-date-range',
    templateUrl: './valio-date-range.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioDateRangeSearchComponent implements OnInit {
  fromDate: Date;
  toDate: Date;
  isDayView: boolean;
  user$: Observable<ValioUser>;

  @ViewChild('datePickerInput', {static: false})
  datePickerInput: ValioDatepickerInputComponent;

  constructor(protected userAccountFacade: UserAccountFacade,
              protected routerService: RoutingService,
              protected cxDate: ValioDatePipe,
              protected route: ActivatedRoute) {
    this.user$ = this.userAccountFacade.get().pipe(map(user => (user as ValioUser)));
    this.isDayView = false;
  }

  ngOnInit() {
    if (this.route.snapshot.url.length >= 3) {
      this.initDates(new Date(this.route.snapshot.url[2].path), false, true);
    }else{
      this.initDates( DateUtils.getNow(), false, false);
    }
  }

  initDates(d: Date, forward: boolean, strictDate: boolean): void {
    this.fromDate = DateUtils.getNow();
    this.fromDate.setTime(d.getTime() + (forward ? weekInMillis : strictDate ? 0 : (-1 * weekInMillis)));
    this.toDate = DateUtils.getNow();
    this.toDate.setTime(d.getTime() + (!forward ? weekInMillis : (-1 * weekInMillis)));
  }

  searchDate(event: NgbDate, from: boolean) {
    if (event) {
      // months are starting from 0 in NgbDate from 1
      const d: Date = new Date(event.year, event.month - 1, event.day);
      if (from) {
        this.fromDate = d;
      } else {
        this.toDate = d;
      }
      this.isDayView = this.fromDate == this.toDate;
    }
  }

  resetView() {
    // months are starting from 0 in NgbDate from 1
    this.initDates(DateUtils.getNow(), true, false);
    this.isDayView = false;
    this.routerService.go('my-account/deliverynotes/');
  }


  createNgbDate(date: Date): NgbDate {
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  convertDate(date: string) {
    return DateUtils.convertDate(date);
  }


}
