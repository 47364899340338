import {Injectable, OnDestroy} from "@angular/core";
import {NgbDatepickerI18n, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {LanguageService, TranslationService} from "@spartacus/core";
import {formatDate, TranslationWidth} from "@angular/common";
import {Subscription} from "rxjs";

@Injectable()
export class ValioNgbDatepickerI18n extends NgbDatepickerI18n implements OnDestroy {
  monthNames: string[] = [];
  shortMonthNames: string[] = [];
  weekdayNames: string[] = [];
  language: string;
  weekHeader: string;

  private subscriptions: Subscription[] = [];

  constructor(private translation: TranslationService, private languageService: LanguageService) {
    super();
    this.populateMonths();
    this.populateWeekDays();
    this.populateLanguage();
    this.populateWeekHeader();
  }

  populateWeekHeader(): void {
    this.subscriptions.push(
      this.translation.translate("deliveryNotes.datePicker.week")
        .subscribe(header => this.weekHeader = header, err => console.error(err))
    );
  }

  /**
   * Populates month names from localized properties
   */
  populateMonths(): void {
    for (let i = 1; i < 13; i++) {
      this.subscriptions.push(this.translation.translate("cart.header.month." + (i))
        .subscribe(month => {
          this.monthNames[i] = month;
          this.shortMonthNames[i] = month.substr(0, 3);
        }, err => console.error(err)))
    }
  }

  /**
   * Populates weekday names from localized properties
   */
  populateWeekDays(): void {
    const weekdayMapKeys: string[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    for (let i = 0; i < weekdayMapKeys.length; i++) {
      this.subscriptions.push(this.translation.translate("calendar." + weekdayMapKeys[i])
        .subscribe(t => {
          this.weekdayNames[i] = t;
        }, err => console.error(err)));
    }
  }

  /**
   * Reads language code
   */
  populateLanguage(): void {
    this.subscriptions.push(this.languageService.getActive().subscribe(lang => this.language = lang, err => console.error(err)));
  }

  ngOnDestroy(): void {
    /* This method has to be called manually at component level on destroy method. ngOnDestroy is not automatically called on injectables */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  getWeekdayShortName(weekday: number): string {
    return this.weekdayNames[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return this.shortMonthNames[month];
  }

  getMonthFullName(month: number): string {
    return this.monthNames[month];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    const date1: Date = new Date(date.year, date.month - 1, date.day);
    return formatDate(date1, 'd.M.y', this.language);
  }
  getWeekdayLabel(weekday: number, width?: Exclude<Intl.DateTimeFormatOptions['weekday'], undefined>): string {
    return this.weekdayNames[weekday - 1];
  }
}
