import { Action } from "@ngrx/store";
import { MULTI_CART_DATA } from "@spartacus/cart/base/core";

import { Order } from "@spartacus/order/root";
import { ValioCart, ValioCartEntryUpdateData } from "./valio-cart.objects";
import { StateUtils } from "@spartacus/core";

export const CART_CHANGE_HEADER = '[Cart] Change header';
export class ValioCartChangeHeader extends StateUtils.LoaderLoadAction {
  readonly type = CART_CHANGE_HEADER;

  constructor(public payload: { userId: string, cartId: string, field: string, value: any, body?: any }) {
    super(MULTI_CART_DATA);
  }
}

export const CART_NULL_ACTION = '[Cart] null';

export class ValioCartNullAction extends StateUtils.LoaderSuccessAction {
  readonly type = CART_NULL_ACTION;

  constructor() {
    super(MULTI_CART_DATA);
  }
}

export const LOAD_USER_DETAILS_FAIL = '[User] Load User Details Fail';
export class ValioLoadUserDetailsFail extends StateUtils.LoaderFailAction {

  readonly type = LOAD_USER_DETAILS_FAIL;
  constructor(public payload: any) {
    super(payload);
  }
}

export const CART_CHANGE_HEADER_RELOAD = '[Cart] Change header reload';

export class ValioCartChangeHeaderReload extends StateUtils.LoaderLoadAction {
  readonly type = CART_CHANGE_HEADER_RELOAD;

  constructor(public reload: boolean, public payload: { userId: string, cartId: string }, public cart?: ValioCart) {
    super(MULTI_CART_DATA);
  }
}

export const VALIO_ADD_EMAIL_TO_CART = '[ValioCart] Change header reload';

export class ValioAddEmailToCart extends StateUtils.EntityProcessesIncrementAction {
  readonly type = VALIO_ADD_EMAIL_TO_CART;

  constructor(
    public payload: { userId: string; cartId: string; email: string }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export const CART_SIMULATE = '[Cart] Simulate';

export class ValioCartSimulate extends StateUtils.LoaderLoadAction {
  readonly type = CART_SIMULATE;

  constructor(public userId: string, public cartId: string) {
    super(MULTI_CART_DATA);
  }
}

export const VALIO_CART_UPDATE_ENTRY = '[Valio-Cart-entry] Update Entry';

export class ValioCartUpdateEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = VALIO_CART_UPDATE_ENTRY;

  constructor(public payload: { userId: string, cartId: string, entry: string, data: ValioCartEntryUpdateData }) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}


export const VALIO_CART_ADD_ENTRY = '[Valio-Cart-entry] Add Entry';

export class ValioCartAddEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = VALIO_CART_ADD_ENTRY;

  constructor(public userId: string, public cartId: string, public productCode: string, public payload: ValioCartEntryUpdateData) {
    super(MULTI_CART_DATA, cartId);
  }
}

export const VALIO_CART_ADD_ENTRY_SUCCESS = '[Valio-Cart-entry] Add Entry Success';

export class ValioCartAddEntrySuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = VALIO_CART_ADD_ENTRY_SUCCESS;

  constructor(public payload: {
    cartId: string;
    userId: string;
    cart: ValioCart;
  }) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export const CART_CHECKOUT = '[Cart] Checkout';

export class ValioCartCheckout extends StateUtils.LoaderLoadAction {
  readonly type = CART_CHECKOUT;

  constructor(public userId: string, public cartId: string, public date: string, public toCreditPage: boolean) {
    super(MULTI_CART_DATA);
  }
}

export const TO_CART_CHECKOUT = '[Cart] Move to Checkout';

export class ValioToCartCheckout extends StateUtils.LoaderSuccessAction {
  readonly type = TO_CART_CHECKOUT;

  constructor(public cart: ValioCart, public userId: string, public cartId: string, public toCreditPage: boolean) {
    super(MULTI_CART_DATA);
  }
}


export const PLACE_ORDER_SUCCESS = '[Cart] Valio Checkout';

export class PlaceOrderSuccess implements Action {
  readonly type = PLACE_ORDER_SUCCESS;

  constructor(public payload: Order) {
  }
}


export const CART_REMOVE_DATE = '[Cart] Remove date';

export class ValioRemoveCartDate extends StateUtils.LoaderLoadAction {
  readonly type = CART_REMOVE_DATE;

  constructor(public userId: string, public cartId: string, public date: string) {
    super(MULTI_CART_DATA);
  }
}
