import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ValioCart, ValioCartEntry, ValioCartEntryUpdateData} from "../../../../services/cart/valio-cart.objects";
import {ValioOrderTemplatesService} from "../../../../services/orderTemplates/valio-order-templates.service";
import {ValioProduct} from "../../../../models";
import {Observable, Subscription} from "rxjs";
import {RouteCalendar} from "../../../../models/valio-calendar.model";
import {ValioDatePipe} from "../../../../services/pipes/valio-date.pipe";
import {ValioRouteCalendarService} from "../../../../services/routecalendar/valio-routecalendar.service";
import {ValioCartService} from "../../../../services/cart/valio-cart.service";

@Component({
    selector: 'valio-cx-order-template-content',
    templateUrl: './valio-order-template-content.component.html',
    standalone: false
})
export class ValioOrderTemplateContentComponent implements OnInit,OnDestroy {
  requestedDate$: Observable<Date>;
  routeCalendar$: Observable<RouteCalendar>;
  orderTemplate: ValioCart;
  isCalendarShown = false;
  enableSaveBtn = false;

  constructor(
    protected orderTemplatesService: ValioOrderTemplatesService,
    protected cxDate: ValioDatePipe,
    protected cdr: ChangeDetectorRef,
    protected routeCalendarService: ValioRouteCalendarService,
    protected cartService: ValioCartService,
  ) {
  }

  private templateSubscription: Subscription;

  ngOnInit(): void {
    this.requestedDate$ = this.cartService.getRequestedDate();
    this.routeCalendar$ = this.routeCalendarService.getRouteCalendar();
    this.templateSubscription = this.orderTemplatesService.getActiveOrderTemplate()
      .subscribe(template => {
        if (template) {
          this.orderTemplate = {...template, entries: [...template.entries]};
          this.orderTemplate.entries.forEach(e => e.statusDisplay = null); // do not show status
          try {
            this.cdr.detectChanges();
          } catch (e) {
            // ignore
          }
        }
      });
  }


  ngOnDestroy() {
    this.templateSubscription.unsubscribe();
  }

  addToTemplate(data: { code: string, item: ValioProduct, data: ValioCartEntryUpdateData }): void {
    const newE = {
      quantity: data.data.qty,
      poNumber: data.data.poNumber,
      editable: true,
      validForOrdering: true,
      deletable: true,
      schoolMilkAllowed: (this.orderTemplate.schoolMilkAllowed && data.item.schoolMilkScheme!=null && data.item.schoolMilkScheme),
      schoolMilkSupport: (this.orderTemplate.schoolMilkAllowed && data.item.schoolMilkScheme!=null && data.item.schoolMilkScheme),   // Default to true if school milk is allowed
      external: true, // how to set apart the updated and added entries
      product: data.item,
      unit: data.item.unit,
      entryNumber: (this.orderTemplate.entries.length + 1)
    } as ValioCartEntry;
    this.orderTemplate.entries.splice(0, 0, newE);
    this.enableSaveBtn = true;
  }

  updateTemplate(data: { item: ValioCartEntry, data: ValioCartEntryUpdateData }): void {
    this.orderTemplate = {
      ...this.orderTemplate,
      entries: this.orderTemplate.entries
        .map(e => {
          const newE = {...e} as ValioCartEntry;
          if (data.item.entryNumber == e.entryNumber) {
            newE.quantity = data.data.qty;
            if (newE.quantity == 0) {
              newE.deleted = true;
            } else {
              newE.deleted = false;
            }
            if (data.data.poNumber) {
              newE.poNumber = data.data.poNumber;
            }
            if (data.data.schoolMilkSupport != null) {
              newE.schoolMilkSupport = data.data.schoolMilkSupport;
            }
          }
          return newE;
        })
    };
    this.enableSaveBtn = true;
  }

  save(): void {
    this.orderTemplatesService.updateTemplate(this.orderTemplate);
    this.enableSaveBtn = false;
  }

  addToCart(date: string): void {
    this.orderTemplate = {
      ...this.orderTemplate,
      requestedDate: date,
      entries: [
        ...this.orderTemplate.entries
      ]
    }
    this.orderTemplate.entries.forEach(e => e.requestedDate = date);
    this.orderTemplatesService.addTemplateToCart(this.orderTemplate);
  }

  filterEntries(entries: ValioCartEntry[]): ValioCartEntry[] {
    return entries.filter(e => !e.deleted);
  }

  showHideCalendar() {
    this.isCalendarShown = !this.isCalendarShown;
    this.cdr.detectChanges();
  }
}
