import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ValioCartPartnerGroupedEntryWrapper} from "../../../../../services/cart/valio-cart.objects";
import {ValioCartService} from "../../../../../services/cart/valio-cart.service";
import {RoutingService} from "@spartacus/core";
import {LaunchDialogService} from "@spartacus/storefront";
import {ValioSwitchPaymentDialogData} from "./valio-switch-payment-layout.config";
import {Subscription} from "rxjs";

@Component({
    selector: 'valio-cx-switch-payment',
    templateUrl: './valio-switch-payment.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioSwitchPaymentComponent implements OnInit, OnDestroy{
    @Input() partnerEntry: ValioCartPartnerGroupedEntryWrapper;
    @Input() toCard: boolean;

    numberOfItems: number;
    private subscriptions: Subscription = new Subscription();

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected cartService: ValioCartService,
        protected routingService: RoutingService) {
    }


    cancel() {
        this.launchDialogService.closeDialog(null);
    }

    switch() {
        this.cartService.switchPayment(this.partnerEntry.partner.code);
        this.cancel();
    }

    ngOnInit(): void {
        this.numberOfItems = this.partnerEntry.entries.filter(e => e.statusDisplay == 'open' && !e.external).length;

        this.subscriptions.add(this.launchDialogService.data$.subscribe((data: ValioSwitchPaymentDialogData) => {
            this.partnerEntry = data.partnerEntry;
            this.toCard = data.toCard;

        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}

