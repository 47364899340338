import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Actions} from "@ngrx/effects";
import {CartActions} from "@spartacus/cart/base/core";
import {ParseResult} from 'ngx-papaparse';
import {Subscription} from "rxjs";
import {ValioCartEntry} from "../../../../services/cart/valio-cart.objects";
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {CSV_IMPORT_SUCCESS} from "../../../../services/cart/valio-csv-import.action";
import {openCloseSpinner} from "../../../../services/util/valio-modals-utils";
import {CsvParserService} from "./CsvParserService";
import {ValioCsvImportValidator} from "./valio-csv-import.validator";
import { LaunchDialogService} from "@spartacus/storefront";
import {DOCUMENT} from "@angular/common";
import {IMPORT_FROM_FILE_SUMMARY} from "./valio-import-from-file-summary-layout.config";

@Component({
    selector: 'valio-cx-import-from-file',
    templateUrl: './valio-import-from-file.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioImportFromFileComponent implements OnInit, OnDestroy {
  counter: number;
  counterArray: Array<number>;
  rawData: string = '';
  importStarted = false;
  form: UntypedFormGroup = new UntypedFormGroup({});
  private subscriptions: Subscription = new Subscription();

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected cdr: ChangeDetectorRef,
    protected cartService: ValioCartService,
    protected actions$: Actions,
    private fb: UntypedFormBuilder,
    protected valioCsvImportValidator: ValioCsvImportValidator,
    protected csvParserService: CsvParserService,
    @Inject(DOCUMENT) protected document: Document
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      csvData: ['', {
        validators: Validators.required,
        asyncValidators: this.valioCsvImportValidator.validate.bind(this.valioCsvImportValidator),
        updateOn: 'change'
      }]
    });

    this.counter = 5;
    this.counterArray = new Array(this.counter);
    this.actions$.subscribe(action => {
      if (action.type === CSV_IMPORT_SUCCESS) {
        this.importStarted = true;
      } else if (this.importStarted && action.type === CartActions.LOAD_CART_SUCCESS) {
        openCloseSpinner(this.document,false);
        this.importStarted = false;
        this.cdr.detectChanges();
        this.dismissModal();
        this.launchDialogService.openDialogAndSubscribe(IMPORT_FROM_FILE_SUMMARY, null);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  dismissModal(): void {
    this.launchDialogService.closeDialog(null);
  }

  onEnter(textarea: HTMLTextAreaElement) {
    textarea.value = textarea.value.split("\t").join(" ");
    let textareaRows = textarea.value.split("\n").length;
    this.counter = textareaRows >= 5 ? ++textareaRows : 5;
    this.counterArray = new Array(this.counter);
  }

  importFromFile(): void {
    const parsedCsv: ParseResult = this.csvParserService.parse(this.form.controls['csvData'].value);
    const updateData: ValioCartEntry[] = parsedCsv.data.filter(d => d.length >= 2).map(d => {
      return d.length > 2 ? {
        product: {code: d[0]},
        quantity: Number(d[1]),
        poNumber: d[2]
      } : {product: {code: d[0]}, quantity: Number(d[1])}
    });
    openCloseSpinner(this.document, true);
    this.importStarted = true;
    this.cartService.addCsvEntriesToCart(updateData);
  }

  getNumber(num: any) {
    return new Array(num);
  }
}
