import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ValioBaseOrder, ValioCart, ValioCartEntry, ValioCartEntryUpdateData} from "../../../../../../services/cart/valio-cart.objects";
import {ValioOccEndpoints} from "../../../../../../valio-occ-endpoints";
import {OccConfig} from "@spartacus/core";
import {ValioDatePipe} from "../../../../../../services/pipes/valio-date.pipe";
import {DateUtils} from "../../../../../misc/util/date-utils";
import {ValioCartService} from "../../../../../../services/cart/valio-cart.service";


@Component({
    selector: 'valio-cart-item-message',
    templateUrl: './valio-cart-item-message.component.html',
    standalone: false
})
export class ValioCartItemMessageComponent {
  @Output()
  remove = new EventEmitter<any>();
  @Output()
  update = new EventEmitter<any>();
  @Output()
  add = new EventEmitter<any>();
  @Input()
  item: ValioCartEntry | ValioCart | ValioBaseOrder;
  @Input()
  compact = false;

  constructor(protected cxDate: ValioDatePipe,
              protected config: OccConfig,
              protected valioCartService: ValioCartService) {

  }

  updateEntryData(data: ValioCartEntryUpdateData) {
    this.update.emit(data);
  }

  mergeEntry(allowMerge: boolean) {
    this.updateEntryData({allowMerge: allowMerge, qty: (this.item as ValioCartEntry).quantity} as ValioCartEntryUpdateData);
  }

  removeItem() {
    this.remove.emit(this.item);
  }

  updateRequestedDate(date: string) {
    this.updateEntryData({
      date: this.cxDate.transform(this.convertDate(date), (this.config.backend.occ.endpoints as ValioOccEndpoints).dateFormat),
      qty: (this.item as ValioCartEntry).quantity
    } as ValioCartEntryUpdateData);
  }

  convertDate(date: string): Date {
    return DateUtils.convertDate(date);
  }

  confirm(confirm: boolean) {
    const entry = (this.item as ValioCartEntry);
    this.add.emit({code: entry.product.code, data: {qty: entry.quantity, confirm: confirm} as ValioCartEntryUpdateData});
  }

  replace(replacementProduct: string) {

    if(replacementProduct) {
      const entry:ValioCartEntry = (this.item as ValioCartEntry);
      this.removeItem();
      this.valioCartService.addEntry(replacementProduct, entry.quantity);
    }
  }
}
