import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {SiteContextComponentService, SiteContextSelectorComponent} from '@spartacus/storefront';
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'valio-cx-site-context-selector',
    templateUrl: './valio-site-context-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioSiteContextSelectorComponent extends SiteContextSelectorComponent {

  constructor(
    componentService: SiteContextComponentService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super(componentService);
  }

  reloadPage(): void {
    this.document.location.reload()
  }
}
