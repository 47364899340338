import {HttpErrorHandler, Priority} from "@spartacus/core";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ValioUnauthorizedErrorHandler extends HttpErrorHandler {

  responseStatus = 401;

  handleError() {
    console.debug("Unauthorized access to backend");
  }

  getPriority?(): Priority | number {
    //return Priority.HIGH;
    // Removed due future issue with  isolatedModules
    return 10;
  }
}
