import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {ProductService} from "@spartacus/core";
import {ValioProduct} from "../../../../models";
import {ValioProductService} from "../../../../services/product/valio-product.service";

@Component({
    selector: 'valio-product-cx-tooltip',
    templateUrl: './valio-product-tooltip.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class ValioProductTooltipComponent implements OnInit, OnDestroy {
  tooltipProduct$: Observable<ValioProduct>;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected productService: ProductService,
    protected cdr: ChangeDetectorRef,
    protected eRef: ElementRef) {
  }

  @Input() showEvents: Observable<string>;
  @Input() showPrice: boolean;

  @Output()
  tooltipClosed = new EventEmitter<any>();

  ngOnInit() {
    this.subscriptions.add(
      this.showEvents.subscribe(productCode => this.toggleTooltip(productCode, this.showPrice))
    );
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target) && !(event.target.classList.contains("btn-cart") || (event.target.parentElement != null && event.target.parentElement.classList.contains("btn-cart")))) {
      this.closeTooltip();
    }
  }

  toggleTooltip(productCode: string, showPrice: boolean) {
    if (productCode && showPrice) {
      this.tooltipProduct$ = (this.productService as ValioProductService).getTooltipProductWithPrice(productCode);
    } else if (productCode) {
      this.tooltipProduct$ = (this.productService as ValioProductService).getTooltipProduct(productCode);
    } else if (!showPrice) {
      this.tooltipProduct$ = null;
    }
    this.cdr.detectChanges();
  }

  closeTooltip() {
    this.toggleTooltip(null, false);
    this.tooltipClosed.emit();
  }
}
