import {Injectable} from '@angular/core';
import {SearchBoxComponentService, SearchBoxConfig} from "@spartacus/storefront";
import {ProductSearchPage, EventService, RoutingService, TranslationService, WindowRef} from "@spartacus/core";
import {ValioSearchboxService, ValioSearchConfig, ValioSearchResults} from "../../../../../services/product/valio-searchbox.service";
import {ValioSuggestiveSearchBoxConfig} from "../../../navigation/suggestive-search-box/valio-suggestive-search-box.module";
import {combineLatest, Observable, of} from "rxjs";
import {map, switchMap, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class ValioSearchBoxComponentService extends SearchBoxComponentService {
  constructor(
    public searchService: ValioSearchboxService,
    protected routingService: RoutingService,
    protected translationService: TranslationService,
    protected winRef: WindowRef,
    protected eventService: EventService
  ) {
    super(searchService, routingService, translationService, winRef,eventService);
  }

  search(query: string, config: ValioSuggestiveSearchBoxConfig): void {
    if (
      config.minCharactersBeforeRequest &&
      query.length < config.minCharactersBeforeRequest
    ) {
      return;
    }

    if (config.displayProducts) {
      this.searchService.search(query, {
        pageSize: config.maxProducts,
        showPrices: config.showPrices,
        baseOrderMode: config.baseOrderMode,
        partner: config.selectedPartner != null ? config.selectedPartner.code : null,
        restrictedAssortment: config.restrictedAssortment != null ? config.restrictedAssortment : null,
        searchCategories: config.searchCategories,
        globalSearch: config.globalSearch
      } as ValioSearchConfig);
    }

    if (config.displaySuggestions) {
      this.searchService.searchSuggestions(query, {
        pageSize: config.maxProducts
      });
    }
  }

  getResults(config): Observable<ValioSearchResults> {
    return combineLatest([
      this.getProductResults2(config),
      this.getProductSuggestions2(config),
      this.getSearchMessage2(config),
    ]).pipe(map(([productResults, suggestions, message]) => {
      return {
        products: productResults ? productResults.products : null,
        pages: productResults ? productResults.pagination : null,
        suggestions,
        message,
      };
    }), tap((results) => this.toggleBodyClass('has-searchbox-results', this.hasResults2(results))));
  }

  hasResults2(results) {
    return ((!!results.products && results.products.length > 0) ||
      (!!results.suggestions && results.suggestions.length > 0) ||
      !!results.message);
  }

  private getProductSuggestions2(config: SearchBoxConfig): Observable<string[]> {
    if (!config.displaySuggestions) {
      return of([]);
    } else {
      return this.searchService.getSuggestionResults().pipe(
        map((res) => res.map((suggestion) => suggestion.value)),
        switchMap((suggestions) => {
          if (suggestions.length === 0) {
            return this.getExactSuggestion2(config).pipe(
              map((match) => (match ? [match] : []))
            );
          } else {
            return of(suggestions);
          }
        })
      );
    }
  }

  private getExactSuggestion2(config: SearchBoxConfig): Observable<string> {
    return this.getProductResults2(config).pipe(
      switchMap((productResult) => {
        return productResult.products && productResult.products.length > 0
          ? this.fetchTranslation2('searchBox.help.exactMatch', {
            term: productResult.freeTextSearch,
          })
          : of(null);
      })
    );
  }

  private fetchTranslation2(
    translationKey: string,
    options?: any
  ): Observable<string> {
    return this.translationService.translate(translationKey, options);
  }

  getProductResults2(config): Observable<ProductSearchPage> {
    if (config.displayProducts) {
      return this.searchService.getResults();
    } else {
      return of({});
    }
  }


  private getSearchMessage2(config: SearchBoxConfig): Observable<string> {
    return combineLatest([
      this.getProductResults2(config),
      this.getProductSuggestions2(config),
    ]).pipe(
      switchMap(([productResult, suggestions]) => {
        if (
          productResult &&
          productResult.products &&
          productResult.products.length === 0 &&
          suggestions &&
          suggestions.length === 0
        ) {
          return this.fetchTranslation2('searchBox.help.noMatch');
        } else {
          return of(null);
        }
      })
    );
  }
}
