import {ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {LaunchDialogService} from "@spartacus/storefront";
import {
    ValioDeliveryNote,
    ValioDeliveryNoteList,
    ValioDeliveryNoteProduct,
    ValioDeliveryNoteProductList,
    ValioReclamationCreateItem
} from "../../../../models/misc.model";
import {Observable, Subscription} from "rxjs";
import {ValioUserService} from "../../../../services/user/valio-user.service";
import {ValioCartEntryUpdateData} from "../../../../services/cart/valio-cart.objects";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ValioRegisterExistingComponent} from "../../user/register/valio-register-existing.component";
import {openCloseSpinner} from "../../../../services/util/valio-modals-utils";
import {tap} from "rxjs/operators";
import {ValioDeliveryNoteReclamationDialogData} from "./valio-deliverynote-reclamation-layout.config";
import {DOCUMENT} from "@angular/common";

const CONTACT_TYPE_EMAIL = '0';

interface Product {
    code: string;
    name: string;
}

@Component({
    selector: 'valio-deliverynote-reclamation',
    templateUrl: './valio-deliverynote-reclamation.component.html',
    standalone: false
})
export class ValioDeliveryNoteReclamationComponent implements OnInit, OnDestroy {
    @Input() deliveryNote: ValioDeliveryNote;
    @Input() deliveryNoteList: ValioDeliveryNoteList;

    productList$: Observable<ValioDeliveryNoteProductList>;
    errorMessage: string;
    deliveryNoteCreated = false;
    submitted: boolean = false;

    private subscriptions: Subscription = new Subscription();

    constructor(protected launchDialogService: LaunchDialogService,
                protected userService: ValioUserService,
                protected cdr: ChangeDetectorRef,
                protected fb: UntypedFormBuilder,
                @Inject(DOCUMENT) protected document: Document) {
    }

    form: UntypedFormGroup;

    ngOnInit() {
        openCloseSpinner(this.document,true)
        this.subscriptions.add(this.launchDialogService.data$.subscribe((data: ValioDeliveryNoteReclamationDialogData) => {
            this.deliveryNote = data.deliveryNote;
            this.deliveryNoteList = data.deliveryNoteList;
            this.productList$ = this.userService.getDeliveryNoteProducts(this.deliveryNote)
                .pipe(tap(p => openCloseSpinner(this.document,false)));
            this.form = this.fb.group(
                {
                    header: this.fb.group({
                        email: [this.deliveryNoteList.email, Validators.required],
                        telephone: [this.deliveryNoteList.phone, ValioRegisterExistingComponent.phoneNumberValidator],
                        note: [null],
                        billingDocument: [this.deliveryNote.billingDocument, Validators.required],
                        deliveryDate: [this.deliveryNote.deliveryDate, Validators.required],
                        partner: [this.deliveryNote.partner.code, Validators.required],
                        contactType: [CONTACT_TYPE_EMAIL, Validators.required]
                    }),
                    items: this.fb.array([]),
                },
                {}
            );
            this.addItem();

        }));

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    addItem() {
        openCloseSpinner(this.document,true);
        const cng = this.form.controls.items as UntypedFormArray;
        cng.push(
            this.fb.group(
                {
                    quantity: [1, Validators.required],
                    material: [null, Validators.required],
                    materialDescription: [null, Validators.required],
                    salesUnit: [null, Validators.required],
                    salesUnitName: [null],
                    remark: [null],
                    reason: [null, Validators.required]
                }
            )
        );
    }

    removeItem(idx: number) {
        const cng = this.getItemsControl();
        delete cng[idx];
    }

    getItemsControl() {
        const itemGroup = <UntypedFormArray>this.form.controls['items'];
        return itemGroup.controls;
    }

    createNote() {
        this.submitted = true;
        if (this.form.valid) {
            openCloseSpinner(this.document,true);

            this.subscriptions.add(this.userService.sendReclamation(this.form.getRawValue())
                .subscribe((mes:string) => {
                        if (mes == null) {
                            this.deliveryNoteCreated = true;
                        } else {
                            this.deliveryNoteCreated = false;
                            this.errorMessage = mes;
                            this.cdr.detectChanges();
                        }
                    },
                    error => {
                        this.deliveryNoteCreated = false;
                        this.errorMessage = JSON.stringify(error);
                        this.cdr.detectChanges();
                    },
                    () => {
                        this.deliveryNoteCreated = true;
                        openCloseSpinner(this.document,false);
                    }
                )
            );
        }
    }

    dismissModal(reason: any) {
        this.launchDialogService.closeDialog(reason); // true for reloading delivery node list after modal close
    }


    convertDate(d: string): Date {
        return new Date(d);
    }

    selectProduct(idx: number, selectedProduct: ValioDeliveryNoteProduct) {
        if (selectedProduct != undefined) {
            this.getItem(idx).controls['materialDescription'].setValue(selectedProduct.product.name);
            this.getItem(idx).controls['salesUnit'].setValue(selectedProduct.salesUnit.code);
            this.getItem(idx).controls['salesUnitName'].setValue(selectedProduct.salesUnit.name);
        }
    }

    updateQty(item: ValioReclamationCreateItem, data: ValioCartEntryUpdateData) {
        item.quantity = data.qty;
    }

    selectReason(item: ValioReclamationCreateItem, reason: any) {
        item.reason = reason.reason;
    }

    isInvalid(fieldName: string) {
        if (this.submitted) {
            return this.form.get(fieldName).invalid;
        }
        return false;
    }

    isInvalidItem(idx: number, fieldName: string): boolean {
        if (this.submitted) {
            let formGroup = this.getItem(idx);
            return formGroup.controls[fieldName].invalid;
        }
        return false;
    }

    hasValue(idx: number, fieldName: string): boolean {
        let formGroup = this.getItem(idx);
        return formGroup.controls[fieldName].value !== '' && formGroup.controls[fieldName].value !== null;
    }

    getItem(idx: number): UntypedFormGroup {
        let formArrayControl = <UntypedFormArray>this.form.get('items');
        return <UntypedFormGroup>formArrayControl.at(idx);
    }

    decrement(idx: number) {
        const qty: number = this.getItem(idx).controls['quantity'].value;
        if (qty > 1) {
            this.getItem(idx).controls['quantity'].setValue(qty - 1);
        }
    }

    increment(idx: number) {
        const qty: number = this.getItem(idx).controls['quantity'].value;
        this.getItem(idx).controls['quantity'].setValue(qty + 1);
    }
}
