import {Component} from '@angular/core';

@Component({
    selector: 'valio-cx-order-template-header',
    templateUrl: './valio-order-template-header.component.html',
    standalone: false
})
export class ValioOrderTemplateHeaderComponent {
  constructor() {
  }
}
