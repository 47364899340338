import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {ProductSearchPage} from '@spartacus/core';
import {Observable} from 'rxjs';
import {ValioUserService} from '../../../../../services/user/valio-user.service';
import {ValioLastPurchasesService} from "../../../../../services/lastPurchases/valio-last-purchases.service";
import {map} from "rxjs/operators";

@Component({
    selector: 'valio-last-purchases',
    templateUrl: 'valio-last-purchases.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValioLastPurchasesComponent {
  isAnonymous$: Observable<boolean>;
  isSignedIn$: Observable<boolean>;
  model$: Observable<ProductSearchPage>;
  size$: Observable<number>;

  @Input() title: string;
  @Input() showScroll: string;

  constructor(protected lastPurchasesService: ValioLastPurchasesService,
              protected userService: ValioUserService) {
  }

  ngOnInit() {
    this.model$ = this.lastPurchasesService.getLastPurchases();
    this.isAnonymous$ = this.userService.isAnonymous();
    this.isSignedIn$ = this.userService.isSignedIn();

    this.size$ = this.model$.pipe(
      map(m => m.products ? m.products.length : 0)
    );
  }
}
