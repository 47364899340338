import {Component} from '@angular/core';

@Component({
    selector: 'valio-cx-register',
    templateUrl: './valio-register.component.html',
    standalone: false
})
export class ValioRegisterComponent {

  existingVisible = false;
  newVisible = false;

  showExisting() {
    this.existingVisible = true;
    this.newVisible = false;
  }

  showNew() {
    this.existingVisible = false;
    this.newVisible = true;
  }
}
