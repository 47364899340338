import {Component, OnInit} from '@angular/core';
import {ValioUserService} from "../../../../services/user/valio-user.service";
import {Observable} from "rxjs";

@Component({
    selector: 'valio-header-news',
    templateUrl: './valio-header-news.component.html',
    standalone: false
})
export class ValioHeaderNewsComponent implements OnInit {
  signedIn$: Observable<boolean>;

  constructor(protected userService: ValioUserService) {
  }

  ngOnInit(): void {
    this.signedIn$ = this.userService.isSignedIn();
  }
}
